.box-contactName {
  background-color: #fafafa;
  padding: 0px 10px;
  border-radius: 5px;
}

.box-contactName > input {
  background-color: #fafafa;
  border: none;
  outline: none;
  width: 100%;
}

.box-contactName.neww {
  background-color: #fafafa;
  border: none;
  outline: none;
  width: 115%;
  /* margin-left: -1px; */
}

.box-contactName.new11 {
  background-color: #fafafa;
  border: none;
  outline: none;
  width: 100%;
  margin-left: 23px;
}

.box-contactName.new33 {
  background-color: #fafafa;
  padding: 0px 10px;
  border-radius: 5px;
  width: 110%;
}

@media screen and (max-width: 982px) {
  .box-contactName.neww {
    background-color: #fafafa;
    border: none;
    outline: none;
    width: 106%;
    margin-left: -3px !important;
  }

  .box-contactName.new11 {
    background-color: #fafafa;
    border: none;
    outline: none;
    width: 106%;
    margin-left: -3px;
  }

  .addclient {
    width: 106%;
  }

  .box-contactName.new33 {
    width: 106% !important;
  }
}

.fntSupp {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
}

.fntSupp3 {
  font-family: Roboto;
  color: #80cfe6;
  font-size: 20px;
}

.fntSupp1 {
  font-family: Roboto;
  color: #003766;
}

.fntSupp4 {
  font-family: Roboto;
  font-size: Regular;
  color: #003766;
}

.fntSupp2 {
  font-family: Roboto;
  font-size: 28px;
  color: #003766;
}

@media screen and (max-width: 327px) {
  .fntSupp2 {
    font-family: Roboto;
    font-size: 22px;
    color: #003766;
  }
}

.notesForm {
  border: 1px solid #80cfe6;
}

.notesForm2 {
  border: 1px solid #daf4fa;
  width: 100%;
  border-radius: 10px;
  height: 82%;
}

.suppInput {
  border: 2px solid #00000029;
  border-radius: 8px;
  padding: 10px;
}

.countBtnchange1 {
  border: 1px solid #003766;
  background-color: transparent;
  color: gray;
  font-weight: 650;
  font-size: 15px;
  border-radius: 10px;
}

.countBtncanc.cancel2 {
  font-size: 15px;
}

.client {
  font-size: 22px;
}

.fntSuppclient {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
  /* margin-left: -9px; */
}

.fntSuppclients {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
  width: 220%;
  /* margin-left: -9px; */
}

.addclienicon {
  width: 43px;
  height: 37px;
}
