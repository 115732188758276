.selected-user-container {
    background: var(--color);
    color: white;
    width: 35px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 400;
    overflow: hidden;
    border: 1px solid var(--color);
    text-transform: uppercase;
}

.user-selection-popup {
    position: absolute;
    width: 230px;
    height: 277px;
    background: #FAFAFA;
    z-index: 11;
    right: 0;
    top: 110%;
    overflow-y: auto;
    padding: 12px;
    gap: 5px;
    display: grid;
    border-radius: 5px;
    box-shadow: 0px 5px 8px rgba(159, 178, 188, 0.25);
}

/* width */
.user-selection-popup::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.user-selection-popup::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 12px;
}

/* Handle */
.user-selection-popup::-webkit-scrollbar-thumb {
    background: rgba(230, 230, 230, 1);
    border-radius: 5px;
}

/* Handle on hover */
.user-selection-popup::-webkit-scrollbar-thumb:hover {
    background: rgba(230, 230, 230, 1);
}

.dd-display-user {
    background: transparent;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 2em;
    cursor: pointer;
}

.dd-display-user:hover {
    background: #fff;
}

.dd-display-user.selected {
    background: #DAF4FA;
}

.dd-user-image {
    width: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid var(--color);
    text-transform: uppercase;
}

.dd-user-name {
    color: #003766;
    font-size: 14px;
}

.dd-user-role {
    color: #7E7E7E;
    font-size: 13px;
}