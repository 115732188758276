.createordermodalone {
  background-color: transparent;
}

.createordermodalone .modal-content {
  background-color: #003766;
  border-radius: 15px;
  color: white;
  margin-left: 50px;
}
.createordermodalone .modalfooter {
  border-radius: 0 0 15px 15px !important;
  height: 70px;
}
.createordermodalone .modal-footer {
  display: block;
  margin: -1px;
  background-color: white;
}
.createordermodalone .nobtn {
  height: 70px;
  background-color: white;
  color: #003766;
  width: 100%;
  margin-top: -17px;
  border: none;
  border-right: 1px solid #003766;
}
.createordermodalone .addbtn {
  height: 70px;
  background-color: white;
  color: #003766;
  width: 100%;
  margin-top: -17px;
  border: none;
  /* border-left: 1px solid #003766; */
}
