.headers {
  background-color: #efefef;
  cursor: pointer;
  font-size: 25px;
  border-radius: 12px;
  border: none;
}
.headersInput {
  border-right: none;
  background-color: #efefef;
  border: none;
  border-radius: 12px;
  font-size: 16px;
}

.drpMenu {
  cursor: pointer;
}
