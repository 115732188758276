body {
  min-height: 100vh;
  background: rgb(250, 250, 250);
}

.themebg {
  background-color: #daf4fa !important;
}

.themecolor {
  color: #003766 !important;
}

.header-title-sk {
  margin-top: 20px !important;
  font-size: 32px !important;
}

.headerbg {
  background: #daf4fa 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 14px 14px 0px 0px;
}

.headerbg2 {
  background: #daf4fa 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 14px 14px 0px 0px;
  height: 127px;
}

.headerbg1 {
  background: #fded91 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 30px 30px 0px 0px;
  height: 127px;
}

.poppinsfont {
  font-family: "Poppins";
}

.linkdecoration {
  text-decoration: none;
}

.alertmsg {
  position: relative;
  width: 70%;
  z-index: 10000;
}

.inputsection {
  margin-top: 15px;
}

.boldtxt {
  font-weight: bold;
}

.createbutton {
  background-color: #003766;
  border-radius: 25px;
  color: #fcfcfc;
  width: 100%;
  height: 40px;
  font-family: "Roboto";
  /* font-weight: bold; */
  font-size: 16px;
  border: 1px solid #003766;
  opacity: 1;
  cursor: pointer !important;
}

.createbuttonfalse {
  background-color: #0036669e;
  border-radius: 25px;
  color: #fcfcfc;
  width: 100%;
  height: 40px;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #0036668f;
  opacity: 1;
  cursor: default !important;
}

.pageminheight {
  min-height: 300px;
}

.headerspace {
  margin-top: 70px;
}

.createbutton:hover {
  background-color: #003766;
  color: #fcfcfc;
  border: 1px solid #003766;
}

.purpeltxt {
  color: #8f2b96 !important;
}

.loginbutton {
  border-radius: 25px;
  border-color: #003766;
  color: #003766;
  width: 100%;
  font-family: "Roboto";
  /* font-weight: bold; */
  font-size: 16px;
  height: 40px;
  cursor: pointer;
}

.loginbutton:hover {
  border-color: #003766;
  color: #003766;
}

.inputsection {
  margin-top: 15px;
}

.passwordicon {
  margin-right: 5px;
  margin-top: 14px;
  color: #a8a8a8;
  cursor: pointer;
  position: absolute;
}

.passwordicon1 {
  cursor: pointer;
  position: absolute;
  margin-top: 7px;
  color: #e1e1e1;
  font-size: 14px;
  z-index: 1000;
}

.btncontainer {
  margin-top: 4%;
  margin-bottom: 4%;
}

.centeralign {
  text-align: center;
}

.rightalign {
  text-align: right;
}

.closeicon {
  cursor: pointer;
  font-size: 23px;
  color: #003766;
  margin-top: -25px;
  /* border:1px solid red; */
  border-radius: 50%;
  position: absolute;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ffffff;
  margin-left: -10px;
}

@media (max-width: 575.98px) {
  .closeicon {
    cursor: pointer;
    font-size: 23px;
    color: #003766;
    margin-top: -8px !important;
    /* border:1px solid red; */
    border-radius: 50%;
    position: absolute;
    padding-right: 10px !important;
    padding-left: 10px !important;
    background-color: #ffffff;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.heading {
  font-family: "Roboto";
  font-size: 33px;
  color: #003766;
  opacity: 1;
  /* font-weight: bold; */
}

.subheading {
  font-family: "Roboto";
  font-size: 15px;
  color: #003766;
  opacity: 1;
}

.socialmediaicon {
  width: 40px;
  height: 40px;
  border: 1px solid #999;
  border-radius: 50%;
  padding: 4px;
  margin: 15px 5px 20px 7px;
  cursor: pointer;
}

.showerror {
  border-color: #d86363;
  background-color: #f9e6e6;
}

.errormsg {
  color: #d86363;
  font-family: "Poppins";
  font-size: 11px;
  position: absolute;
  margin-top: 35px;
}

.inputtext {
  font-size: 15px;
  font-family: "Roboto";
}

#userinput::placeholder {
  color: #003766;
}

.checkbox1 {
  width: 13px;
  height: 13px;
  /* background-color: darkblue; */
}

/* profile css */
.settinglabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 53px; */
  color: #003766;
  cursor: pointer;
  align-self: center;
}

.profileHeading {
  color: #daf4fa;
}

.editFunc {
  cursor: pointer;
}

.profileInput {
  border-radius: 10px;
  border: 2px solid #003766;
  padding: 3px;
}

.profileSubbtn {
  color: white;
  background-color: #003766;
  border: none;
  border-radius: 10px;
  width: 140px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #003766;
  border-bottom: 16px solid #003766;
  width: 80px;
  height: 80px;
  /* -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite; */
}

.expMenu {
  position: absolute;
  margin-left: "70% !important";
}

.dragcardWidth {
  /* display: flex; flex-wrap: wrap; width: 665px; margin-bottom: 12px; overflow-x: scroll;
  white-space: nowrap */
  overflow-x: scroll;
  overflow-y: hidden;
}

.table> :not(:first-child) {
  /* border-top: 1.5px solid #8080807a; */
  border-top: 0.5px solid #8080807a;
}

.scrollsection {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 60px;
  padding-right: 90px;
}

/* @media (max-width: 575.98px) {
  .scrollsection {
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
  }
} */

.alertmodal .okbtn {
  height: 57px;
  background-color: transparent;
  color: #003766;
  width: 100%;
  margin-top: -27px;
  border: none;
}

.alertmodal {
  background-color: transparent;
}

.alertmodal .modal-content {
  background-color: #003766;
  border-radius: 15px !important;
  color: white;
}

.alertmodal .modalfooter {
  border-radius: 0 0 15px 15px !important;
  height: 57px;
}

.alertmodal .modal-footer {
  display: block;
  margin: -1px;
}

.alertmodal .nobtn {
  height: 80px;
  background-color: transparent;
  color: #003766;
  width: 100%;
  margin-top: -17px;
  border: none;
  border-right: 1px solid #003766;
}

.modal .modal-content {
  border-radius: 15px;
  /* border: 2px solid red; */
}

.pac-container {
  z-index: 10000 !important;
}

tr.new-row td {
  background: #daf4fa;
  filter: drop-shadow(0px 8px 25px #00376614);
}

/* textarea::-webkit-resizer {
  border-width: 1px;
  border-style: solid;
  border-color: transparent #80CFE6 #80CFE6 transparent;
  transform: rotate(45deg)
} */

.attachments-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;
  padding: 3px;
}


.attachments-container .badge {
  background: #f0f0f0;
  color: gray;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border: none !important;
}

.css-1wy0on6 {
  display: none;
}

@media screen and (max-width: 767px) {
  .moblieRes {
    width: 90%;
    padding-left: 12%;
  }

}