.proCard {
  background: #eff0f8;
  height: 31vw;
}

.centertext {
  text-align: center;
}

.add_button {
  border: none;
  background: none;
}

.proCardBtn {
  height: 6rem;
  width: 9rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ced4da;
  color: #7e7e7e;
}

@media screen and (max-width: 400px) {
  .proCardBtn {
    height: 4rem;
    width: 6rem;
    border-radius: 10px;
    border: 2px solid #ced4da;
    color: #7e7e7e;
  }
}

.countBtncanc21 {
  font-size: 15px;
  color: #003766;
  font-weight: 500;
  border: 1px solid #003766;
  background: white !important;
  border-radius: 10px;
}

.countBtnchange22 {
  border: 1px solid #003766;
  background-color: #003766;
  color: white;
  font-weight: 650;
  font-size: 15px;
  border-radius: 10px;
}

.sup12 .form-select:focus {
  border-color: #f5e7e7 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem grey !important;
}

/* for Costum Select start */

.custom-select-box.custom-with-input {
  padding: 0 0;
}

.custom-select-box.custom-with-input .form-control {
  border: none;
}

.custom-select-box.custom-with-input .custom-select-outer .list-select-box {
  padding: 2px 10px 2px 10px;
}

/* for Costum Select end */

.sk-custom-container-comp {
  /* position: relative; */
  position: absolute !important;
  width: 85%;
  /* box-sizing: border-box; */
  border: 2px solid #00000029;
  font-size: 16px;
  border-radius: 8px;
  /* background-color: #fafafa; */
  padding: 0;
}

.field-disable1 {
  pointer-events: none;
}

.imghead {
  color: #80cfe6;
  padding-left: 30px;
  font-size: 17px;
}

.imghead2 {
  color: #80cfe6;
  font-size: 17px;
}

.sk-custom-container.sk-custom-container-comp.with-width-93 {
  width: 93%;
}
.addProductFnt4New {
  height: 51px;
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
}
.addProductFnt4New:first-child {
  margin-top: 31px !important;
}