.homeback {
  background: #fafafa;
}

.home_scroll {
  /* overflow-x: scroll; */
  overflow-y: hidden;
}

.profileButton {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  border: none;
  color: white;
  background-color: #80cfe6;
  font-size: 20px;
  font-weight: 700;
}

.dropdown-toggle.profileButton::after {
  display: none;
}

.dropdown-toggle.profileButton:active {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  border: none;
  color: white;
  background-color: #80cfe6;
  font-size: 20px;
  font-weight: 700;
}

.dropdown-toggle.profileButton:hover {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  border: none;
  color: white;
  background-color: #80cfe6;
  font-size: 20px;
  font-weight: 700;
}

.dropdown-toggle.profileButton:focus {
  box-shadow: none !important;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  border: none;
  color: white;
  background-color: #80cfe6;
  font-size: 20px;
  font-weight: 700;
}

.headerIcon {
  font-size: 30px;
  padding: 0;
}

.headerText {
  font-size: 18px;
  font-weight: 600;
  color: #003766;
}

.homeBox {
  font-size: 30px;
}

.homeCards {
  color: #003766;
  /* box-shadow:2px 2px ; */
  border-radius: 10px;
  background-color: white;
}

.homeCardDigit {
  /* font-size: 18px; */
  color: #80cfe6;
  font-weight: 700;
}

.homeCardText {
  font-size: 10px;
  color: #003766;
}

.homeCardLastcol {
  background-color: #fafafa;
  box-shadow: 0px 3px 10px #00000029;
}

.arrivingtext1 {
  font-size: 12px !important;
  color: #C1C1C1;

}

.arrivingtext2 {
  color: #C1C1C1;
}

.plussSign {
  color: #C1C1C1;
  width: 13px;
  height: 16px;
}

.Home_Scrollsection {
  /* overflow-x: scroll; */
  /* overflow-y: hidden; */
  white-space: nowrap;
  padding-bottom: 60px;
  padding-right: 90px;
}