/* .scrollwidth{
    min-width: 130%;
}
@media (max-width: 1399.98px) {
    .scrollwidth{
        min-width: 180%;
    }
}

@media (max-width: 1199.98px) {
    .scrollwidth{
        min-width: 210%;
    }
}
@media (max-width: 991.98px) {
    .scrollwidth{
        min-width: 200%;
    }
}
@media (max-width: 767.98px) {
    .scrollwidth{
        min-width: 285%;
    }
}
@media (max-width: 575.98px) {
    .scrollwidth{
        min-width: 100%;
    }
} */

.scrollwidth {
    /* min-width: 1617px; */
    min-width: 1200px;
}

@media (max-width: 575.98px) {
    .scrollwidth {
        /* min-width: 100%; */
        /* overflow-x: scroll; */
    }
}

@media (min-width: 2270px) and (max-width: 2670px) {
    .scrollsection {
        overflow-x: hidden !important;
        padding-bottom: 0px !important;
    }

    .scrollwidth {
        position: relative;
        /* left: 24rem; */
        left: 4rem;
    }
}

@media (min-width: 2001px) and (max-width: 2270px) {
    .scrollsection {
        overflow-x: hidden !important;
        /* padding-bottom: 0px !important; */
    }

    .scrollwidth {
        position: relative;
        left: 18rem;
    }
}

@media (min-width: 1800px) and (max-width: 2000px) {

    .scrollsection {
        overflow-x: hidden !important;
        /* padding-bottom: 0px !important; */
    }


}

@media (min-width: 1680px) and (max-width: 1799px) {

    .scrollsection {
        overflow-x: hidden !important;
        /* padding-bottom: 0px !important; */
    }

    .scrollwidth {
        position: relative;
        left: 2rem;
    }
}