.createordermodal {
  background-color: transparent;
}

.createordermodal .modal-content {
  background-color: #003766;
  border-radius: 15px;
  color: white;
  width: 366px;
  height: 220px;
  margin-left: 50px;
}

.createordermodal .modalfooter {
  border-radius: 0 0 15px 15px !important;
  height: 50px;
  padding-top: 8px !important;
}

.createordermodal .modal-footer {
  display: block;
  margin: -1px;
  background-color: white;
}

.createordermodal .nobtn {
  height: 49px;
  background-color: transparent;
  /* color: #ffffff; */
  color: #003766;
  width: 100%;
  margin-top: -20px;
  font-size: 17px;
  border: none;
  font-weight: 700;
  /* border-right: 1px solid #ffffff; */
  border-right: 1px solid #003766;
}

.createordermodal .addbtn {
  height: 49px;
  background-color: transparent;
  /* color: #ffffff; */
  color: #003766;
  width: 100%;
  margin-top: -20px;
  font-size: 17px;
  font-weight: 500;
  border: none;
}