.wareimgInv {
  height: 100px;
}

@media screen and (max-width: 767.9px) {
  /* .stockavInv {
        width: 45vw;
    } */

  .card-title {
    padding-top: 8px;
  }

  .calInv {
    width: 8vw !important;
  }

  .homeCardLastcol .stock-avail .stock-avail-title {
    width: 100% !important;
  }

  .purchasingOrder-section .sort-section {
    width: inherit;
  }

  .backOrder .backOrder-title {
    width: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .purchasingOrder-section .sort-section {
    width: inherit !important;
    margin: 10px 0;
  }

  .cardBorder-data {
    margin-bottom: 10px !important;
  }

  .backOrder .backOrder-title {
    width: 100%;
  }
}

.calInv {
  width: 2vw;
}

.shelInv {
  width: 3vw;
  padding-bottom: 8px;
}

.scroll {
  /* overflow: scroll;
  height: 500px; */
  overflow-x: scroll;
  display: block;
}

.tableHover:hover {
  background-color: #daf4fa;
}

.fntClr tr {
  border-bottom-color: #ddd;
}

.fntClr {
  color: #003766;
}

.textDeco {
  /* text-decoration: underline; */
  cursor: pointer;
}

.homeBox {
  font-size: 30px;
}

.homeCards {
  color: #003766;
  /* box-shadow:2px 2px ; */
  border-radius: 10px;
  background-color: white;
}

.homeCardDigit {
  /* font-size: 18px; */
  color: #80cfe6;
  font-weight: 700;
}

.homeCardText {
  font-size: 16px;
  color: #003766;
  font-weight: 500;
}

.homeCardLastcol {
  background-color: #fafafa;
  cursor: pointer;
  box-shadow: 0px 5px 15px #00376640;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 15px;
}

.homeCardLastcol .stock-avail {
  align-items: center;
  justify-content: center;
  display: flex;
}

.homeCardLastcol .stock-avail img {
  height: 4rem;
  /* width: 100%; */
  color: #003766;
}

.homeCardLastcol .stock-avail h5 {
  font-weight: bold;
}

.homeCardLastcol-advised {
  background-color: #e6e6e680;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 5px 15px #00376640;
}

.homeCardLastcol-advised .stock-avail {
  display: flex;
  padding: 0 6px;
}

.homeCardLastcol-advised .stock-avail img {
  height: 4rem;
  /* width: 100%; */
  color: #003766;
}

.homeCardLastcol-advised .stock-avail .show_more {
  font-size: 0.9rem;
  cursor: pointer;
  color: #003766;
}

/* purchasingOrder section start */
.purchasingOrder-section {
  margin-top: 2rem;
}

.purchasingOrder-section .sort-section .sort-order {
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
}

.purchasingOrder-section .sort-section .date-order {
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.purchasingOrder-section table.table tr td {
  padding: 10px;
  padding-bottom: 0;
  /* padding-bottom: 22px; */
}

.purchasingOrder-section .sort-section .date-order .asc {
  transform: rotate(180deg);
}

.purchasingOrder-section .sort-section .sort-order img {
  transform: rotate(90deg);
  height: 5px;
}

.purchasingOrder-section .sort-section .sort-order .asc {
  transform: rotate(270deg);
}

.purchasingOrder-section .fntClr tr td {
  padding: 0;
}

.purchasingOrder-section .fntClr h6 {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
}

.purchasingOrder-section .fntClr td .w-max-content {
  width: max-content;
  gap: 10px;
  font-size: 18px;
  line-height: 29px;
  color: #003766;
  font-weight: 500;
}

.purchasingOrder-section .fntClr td .w-max-content h5 {
  color: #003766;
  font-weight: bold;
  font-size: 18px;
}

/* purchasingOrder section end */

.advised-section .advised-heading p {
  color: #80cfe6;
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
}

.advised-section .advised-contents p {
  font-size: 1rem;
  margin: 0;
}

.advised-section .advised-contents h6 {
  font-size: 1.3rem;
  font-weight: bold;
}

.stock-avail .stock-avail-title {
  width: 60% !important;
}

.warehouses-table-data .fntClr td {
  min-width: 262px;
}

.backOrder {
  background-color: #e6e6e6;
  border-radius: 15px 15px 0px 0px;
  border: 2px 2px 0px 2px solid rgba(128, 128, 128, 0.414);
  height: 90px;
  display: flex;
  align-items: center;
  padding-left: 52px;
}

.backOrder img {
  height: 2vw;
}

.backOrder .backOrder-title h3 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #003766;
}

.backOrder .backOrder-title {
  width: 50%;
}

.backOrder .backOrder-right-side h3 {
  font-size: 20px;
  line-height: 18px;
  margin: 0;
  padding: 0;
}

.purchasingOrder-section h4 {
  color: #003766;
  font-size: 20px;
  line-height: 37px;
  padding-bottom: 22px;
}

.ps-backOrder .backOrder-title h3 {
  font-size: 18px;
  color: #003766;
  font-weight: 500;
}

.cardBorder-data .part-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.cardBorder-data .part-section .heading-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.cardBorder-data .part-section .heading-title .heading {
  flex: 1;
  font-size: 16px;
  color: #80cfe6;
  font-family: Roboto;
  margin: 0;
}

.part-contents .sk-custom-container {
  border: none;
  background: none;
}

.part-contents .sk-custom-container .sk-custom-select__menu {
  background: #f7f7f7 !important;
  position: absolute !important;
}

.part-contents .sk-custom-container .sk-custom-select__option {
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  color: #003766 !important;
}

.part-contents .select-part {
  flex: 1 1;
  margin: 0;
}

.part-contents .select-part .sk-custom-container .sk-custom-select__option--is-selected {
  font-weight: 500 !important;
}

.submitPartNew {
  color: #003766 !important;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.part-contents .sk-custom-container .sk-custom-select__value-container {
  padding: 0;
  font-size: 18px;
}

.cardBorder-data .part-section .heading-title .part-calc {
  display: flex;
  justify-content: space-between;
  flex: 0.7;
  text-align: center;
}

.cardBorder-data .part-section .heading-title .part-calc h4 {
  font-size: 16px;
  color: #80cfe6;
  flex: 1;
  margin: 0;
  padding: 0;
}

.cardBorder-data .part-section .heading-title .part-calc h3 {
  font-size: 16px;
  color: #c1c1c1;
  flex: 1;
  margin: 0;
}

.cardBorder-data .part-section .heading-title .part-calc .custom-radio {
  font-size: 14px;
  color: #c1c1c1;
  flex: 1;
}

.backOrder .backOrder-right-side-data h3 {
  color: #003766;
  font-size: 22px;
}

.part-contents .heading {
  color: #003766 !important;
  font-size: 20px;
  line-height: 26px;
}

.part-contents .part-calc h4 {
  color: #003766 !important;
  font-size: 20px;
  line-height: 26px;
}

.part-contents .part-calc h3 {
  color: #c1c1c1;
  font-size: 20px;
  line-height: 26px;
}

.purchasingOrder-section .textDeco {
  font-size: 22px;
  line-height: 29px;
  font-weight: bold;
}

.backOrder-right-side-data h3 {
  font-size: 18px;
  line-height: 29px;
  color: #003766;
}

.cardBorder-data {
  border-bottom: 20px solid rgba(0, 0, 0, 0.125) !important;
  padding: 11px !important;
  border-radius: 0 0 10px 10px !important;
  min-height: 450px;
  margin-bottom: 150px;
  box-shadow: 0px 3px 10px #00000029;
}

.heading-title .form-control {
  font-size: 18px !important;
  color: #000 !important;
  font-weight: 400;
  /* margin-right: 10px; */
}

.heading-title .part-calc,
.heading-title .custom-radio {
  /* margin-left: 10px;
    margin-right: 10px; */
}

.part-section .form-row {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.CrsrPointr {
  cursor: pointer;
}

.comment-section {
  margin-block: 30px;
}

.comment-section form {
  /* width: 75%; */
  width: 63%;
}

.comment-section form input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  /* border: 0.5px solid #003766; */
  color: #b4b4b4;
  font-size: 16px;
}

.comment-section .sendBtn {
  background: #003766;
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.comment-section1 {
  margin-block: 30px;
}

.custom-row-warhorse {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

/* .profileButtonNew {
    border-radius: 50%;
    height: 47px;
    width: 47px;
    border: none;
    color: white;
    background-color: #80cfe6;
    font-size: 20px;
    font-weight: 700;
} */

.comment-section {
  background: #fff;
  display: flex;
  margin-left: -11px;
  margin-right: -11px;
  margin-top: -19px;
  border-radius: 0 0 10px 10px;
  margin-bottom: 70px;
}

.comment-section button {
  flex: 1 50%;
  margin: 0;
  border: none;
  line-height: 0.5;
  padding: 10px;
  border-top: 1px solid #003766;
  margin-top: -29px;
  /* height: 60px; */
  height: 80px;
  background-color: #fff;
  border-radius: 0 0 0px 10px;
}

.comment-section button:first-child {
  border-right: 1px solid#003766;
  font-size: 22px;
  color: #003766;
  border-radius: 0;
}

.comment-section button:last-child {
  border-radius: 0 0 10px 0px;
  font-size: 22px;
  color: #003766;
  font-weight: bold;
}

.Warehouse .form-row .wrapper {
  background-color: none;
  border: none;
  border-bottom: 1px solid #b4b4b4;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.Warehouse .custom-color {
  font-size: 28px;
  color: #003766;
}

.Warehouse .part-calc input[type="number"]::-webkit-inner-spin-button,
.Warehouse .part-calc input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Warehouse .part-calc input:focus {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #b4b4b4;
}

.Warehouse .part-calc input {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #b4b4b4;
  border-radius: 0px;
}

.Warehouse .custom-radio {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Warehouse .part-calc input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid currentColor;
  transform: translateY(-0.075em);
  place-content: center;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Warehouse .part-calc input[type="checkbox"]::before {
  content: "";
  width: 0.85em;
  height: 0.85em;
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #003766;
  border-radius: 15px;
}

.Warehouse .part-calc input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.Warehouse .commit img {
  width: 1.5vw;
}



.Warehouse .commit .commit-description h2 {
  font-size: 16px;
  margin: 0;
}

.addNewComment .profileButton12 {
  margin-left: 6px;
}

.addNewComment .commit-description1 {
  margin-left: 18px;
}

.Warehouse .commit .commit-title {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.Warehouse .commit .commit-title h2 {
  font-size: 18px;
  color: #003766;
}

.Warehouse .commit .commit-title p {
  font-size: 18px;
  color: #80cfe6;
  margin: 0;
  cursor: pointer;
}

.Warehouse .commit .commit-main {
  /* border-bottom: 1px solid #ddd; */
  width: 77%;
  margin: 0 auto;
  padding-bottom: 10px;
  /* margin-bottom: 10px; */
}

/* .comment-section .commentScroll {
    max-height: 251px;
    overflow-y: scroll;
} */

.profileButton12 {
  border-radius: 50%;
  height: 47px;
  width: 47px;
  border: none;
  color: white;
  background-color: #80cfe6;
  font-size: 20px;
  font-weight: 700;
}

.profileButton12:hover {
  color: white;
  background-color: #80cfe6;
  /* border-radius: 50%;
    height: 50px;
    width: 50px;
    border: none;
    background-color: #80cfe6;
    font-size: 20px;
    font-weight: 700; */
}

@media screen and (max-width: 1440px) {
  .purchasingOrder-section .fntClr h6 {
    font-size: 18px;
  }

  .purchasingOrder-section .fntClr td .w-max-content h5 {
    font-size: 20px;
  }

  .warehouses-table-data .fntClr td {
    min-width: 250px;
  }

  .part-contents .heading {
    font-size: 20px !important;
  }

  .cardBorder-data .part-section .heading-title .heading {
    font-size: 18px !important;
  }

  .cardBorder-data .part-section .heading-title .part-calc h4 {
    font-size: 18px;
  }

  .cardBorder-data .part-section .heading-title .part-calc h3 {
    font-size: 18px;
  }

  .Warehouse .part-calc input[type="checkbox"] {
    width: 0.85em;
    height: 0.85em;
  }

  .Warehouse .part-calc input[type="checkbox"]::before {
    width: 0.55em;
    height: 0.55em;
  }

  .ps-backOrder .backOrder-title h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .purchasingOrder-section h4 {
    font-size: 22px;
  }

  .purchasingOrder-section .fntClr h6 {
    font-size: 18px;
  }

  .purchasingOrder-section .fntClr td .w-max-content h5 {
    font-size: 18px;
  }

  .warehouses-table-data .fntClr td {
    min-width: 200px;
  }

  .purchasingOrder-section .fntClr td .w-max-content {
    font-size: 18px;
  }

  .backOrder-right-side-data h3 {
    font-size: 18px;
  }
}

.ttt.emoji-picker-style {
  position: absolute;
  left: 70px;
  bottom: 37%;
  display: inline-block;
}

.ttt .EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-content {
  grid-gap: 40px 0;
}

.ttt .EmojiPickerReact .epr-category-nav {
  display: unset;
}

.ttt .EmojiPickerReact .epr-skin-tones .epr-tone {
  bottom: -5px;
}

.ttt .EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-content {
  margin-top: 25px;
}

.ttt.comment-section button:first-child {
  border-right: unset;
}

.draggedOn .tableHover.fntClr {
  background-color: #daf4fa;
}

.part-contents {
  border-bottom: 1px solid #ddd;
  padding: 4px 0;
}

.asc66 {
  display: flex;
  justify-content: end !important;
  align-items: end !important;
}

.asc77 {
  display: flex;
  justify-content: start !important;
  align-items: start !important;
}