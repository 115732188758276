.countFontclr {
  color: #003766;
}

.cardHead {
  background: #e6e6e6;

  /* border-radius: 0px; */
  border: none;
  border-radius: 20px 20px 0px 0px;
}

.countProicon {
  width: 38px;
  height: 44px;
}

.custCardCount {
  border-radius: 10px;
}

.text_Border {
  font-size: 16px;
  border: 1px solid #003766;
}

.form-control:focus {
  box-shadow: none !important;
  /* border-color: #ced4da; */
  border-color: #003766;
}

.countBtn {
  border: none;
  background: #daf4fa !important;
  color: #003766;
  font-weight: 650;
  font-size: 15px;
  border-radius: 10px;
}

.countBtnchange {
  border: none;
  background: #003766 !important;
  color: white;
  font-weight: 650;
  font-size: 15px;
  border-radius: 10px;
}

.countBtncanc {
  border: 1px solid #003766;
  background: white !important;
  color: #003766;
  font-weight: 650;
  font-size: 15px;
  border-radius: 10px;
}

.countBtnDis {
  border: none;
  background: #e6e6e6 !important;
  color: #ffffff;
  font-weight: 700;

  font-size: 15px;
  border-radius: 10px;
}

.stckFont {
  /* font-size: calc(20% + 2vw); */
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .stckFont {
    /* font-size: calc(20% + 3vw); */
  }
}

@media screen and (max-width: 568px) {
  .stckFont {
    /* font-size: calc(20% + 4vw); */
  }
}

.cntStockfont {
  font-size: 22px;
}

@media screen and (max-width: 767px) {
  .cntStockfont {
    /* font-size: calc(20% + 2vw); */
    font-size: 22px;
  }
}

@media screen and (max-width: 568px) {
  .cntStockfont {
    font-size: calc(20% + 3vw);
  }
}

.sendbtn {
  right: 40px !important;
  margin-top: -30px !important;
}

.countstockbox {
  width: 562px;
}

.countstockbox .form-select:focus {
  border-color: grey;
  outline: 0;
  /* box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%); */
  box-shadow: 0 0 0 1px grey;
}

.countinputtext {
  font-size: 20px;
  color: #003766;
  font-family: "Roboto, Regular";
  background-color: #e9ecef;
}

.symbolicon {
  font-size: 30px;
  color: #003766;
}

.stock .sk2-custom-container {
  position: absolute !important;
  box-sizing: border-box;
  border: 2px solid #00000029;
  font-size: 16px;
  border-radius: 8px;
  background-color: #fafafa;
  padding: 0;
  width: 98%;
}

.count_Dlt {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.comment_Deleted {
  font-size: 16px;
  border-radius: 0.375rem;
  border: 1px solid #003766;
  /* color: #A5A5A5; */
  line-height: 1.5;
}