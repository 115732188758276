.ordersbg {
  min-height: 110px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 15px !important;
  opacity: 1;
  border: none;
  height: auto;
  /* overflow: hidden; */
}

.orderheader {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border: none;
  min-height: 55px;
}

.orderheaderbg {
  background-color: #fded91;
}

.orderbody {
  border: none;
  min-height: 55px;
}

.carddata {
  font-size: 12px;
  font-family: "Roboto";
}

.homeBox1 {
  font-size: 15px;
}

.assignee-error-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #fc255f;
  right: -2%;
  top: -10%;
  border-radius: 100%;
  position: absolute;
  color: white;
  text-align: center;
  cursor: default;
  z-index: 10;
}

.error-message {
  position: absolute;
  display: inline-block;
  width: 135px;
  height: 45px;
  border-radius: 6px;
  background-color: #fc255f;
  z-index: 50;
  cursor: pointer;
  left: 42%;
  top: -77%;
  color: white;
  font-size: 12px;
  padding: 5px 0 0 5px;
}

.error-msg-style {
  margin: 0;
}

#pipeline-modal {
  width: 370px;
}

#pipeline-modal>.modal-content {
  width: 369px;
}

.modal-main-div {
  width: 100%;
  height: 152px;
  background-color: #fc255f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 40px 10px;
  color: white;
  text-align: center;
}

.modal-main-btns {
  display: grid;
  height: 70px;
  grid-template-columns: 1fr auto 1fr;
  text-align: center;
  align-items: center;
}

.cancel-btn-style {
  font-size: 16px;
  color: #003766;
  cursor: pointer;
  font-weight: 400;
}

.ok-btn {
  font-weight: bold;
}

.btn-line {
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: gray;
}

.rbc-event .assignee-error-icon {
  z-index: 9999;
  left: 3px;
  width: 15px;
  height: 15px;
  top: 3px;
}

.rbc-event .error-message {
  left: -123px;
  top: -38px;
  z-index: 9999;
}

.rbc-row-segment {
  position: relative;
}

.rbc-day-slot .rbc-event-content {
  overflow: hidden;
  border-radius: 8px;
}

.rbc-day-slot .rbc-event {
  overflow: visible !important;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  overflow-y: visible !important;
}

.rbc-row-content-scrollable {
  overflow-y: auto;
}

/* width */
.rbc-row-content-scrollable::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.rbc-row-content-scrollable::-webkit-scrollbar-track {
  background: #fafafa;
}

/* Handle */
.rbc-row-content-scrollable::-webkit-scrollbar-thumb {
  background: #e3e3e3;
}

/* Handle on hover */
.rbc-row-content-scrollable::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.delete-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #FFFFFF;
}

.delete-button {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #003766;
  line-height: 21px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  justify-content: start;
}

.delete-button:hover {
  font-weight: bold;
}