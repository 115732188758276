.box-contactName {
  background-color: #fafafa;
  padding: 0px 10px;
  border-radius: 5px;
}

.box-contactName > input {
  background-color: #fafafa;
  border: none;
  outline: none;
  width: 100%;
}

.box-contactName.new {
  background-color: #fafafa;
  border: none;
  outline: none;
  width: 113%;
  margin-left: -3px;
}

.box-contactName.new1 {
  background-color: #fafafa;
  border: none;
  outline: none;
  width: 100%;
  margin-left: 18px;
}

@media screen and (max-width: 982px) {
  .box-contactName.new1 {
    background-color: #fafafa;
    border: none;
    outline: none;
    width: 106%;
    margin-left: -3px !important;
  }

  .box-contactName.new {
    background-color: #fafafa;
    border: none;
    outline: none;
    width: 106%;
    margin-left: -3px;
  }

  .upclient {
    width: 104%;
  }

  .box-contactName.new3 {
    width: 104% !important;
  }
}

.box-contactName.new3 {
  background-color: #fafafa;
  padding: 0px 10px;
  border-radius: 5px;
  width: 107%;
}

.fntSupp {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
}

.fntSuppclient {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
  /* margin-left: -9px; */
}

.fntSupp3 {
  font-family: Roboto;
  /* font-size: 22px; */
  color: #80cfe6;
}

/* @media screen and (max-width: 357px) {
    .fntSupp {
      font-family: Roboto;
      font-size: 20px;
      color: #80cfe6;
    }
  } */

.fntSupp1 {
  font-family: Roboto;
  /* font-size: 22px; */
  color: #003766;
}

.fntSupp4 {
  font-family: Roboto;
  font-size: Regular;
  color: #003766;
}

.fntSupp2 {
  font-family: Roboto;
  font-size: 28px;
  color: #003766;
}

@media screen and (max-width: 327px) {
  .fntSupp2 {
    font-family: Roboto;
    font-size: 22px;
    color: #003766;
  }
}

.notesForm {
  border: 1px solid #80cfe6;
}

.suppInput {
  border: 2px solid #00000029;
  border-radius: 8px;
  padding: 10px;
}

/* .pagination1 {
      float: right;
      margin: 0 0 5px;
  }
  .pagination1 li a {
      border: none;
      font-size: 13px;
      min-width: 30px;
      min-height: 30px;
      color: #999;
      margin: 0 2px;
      line-height: 30px;
      border-radius: 50% !important;
      text-align: center;
      padding: 0 6px;
  }
  .pagination1 li a:hover {
      color: #666;
  }
  .pagination1 li.active a, .pagination li.active a.page-link {
      background: #80CFE6;
  }
  .pagination1 li.active a:hover {        
      background: #80CFE6;
  }
  .pagination1 li.disabled i {
      color: #FAFAFA;
  }
  .pagination1 li i {
      font-size: 16px;
      padding-top: 6px
  } */
.countBtnchange1 {
  border: 1px solid #003766;
  background-color: transparent;
  color: gray;
  font-weight: 650;
  font-size: 15px;
  border-radius: 10px;
}

.vwprt {
  height: 100vh;
}

.fntSupp11 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #003766;
  margin-top: 5px;
}

.stckFont {
  font-size: 28px;
}

.custCardCount {
  filter: drop-shadow(0px 3px 10px #00000029);
}

.countBtncanc.cancel {
  font-size: 15px;
  color: #003766;
  font-weight: 500;
}

.client {
  font-size: 22px;
  color: #003766;
}

.addclienicon {
  width: 43px;
  height: 37px;
}
