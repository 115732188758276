.outer {
  background-color: #fafafa;
  width: 80%;
  height: 100%;
  align-items: center;
}

.Add1 {
  max-width: 97px;
  max-height: 97px;
  cursor: pointer;
  /* background: transparent url('img/Ellipse 39.png') 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 3px 6px #00000029; */
  opacity: 1;
}

.tableWrapper {
  background: #fafafa;
  padding: 0 0 10px 0;
  align-items: center;
  position: relative;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

@media screen and (max-width: 1225px) {
  .tableWrapper {
    background: #fafafa;
    align-items: center;
    position: relative;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.fnt1 {
  font-family: Roboto;
  font-size: medium;
  color: #c1c1c1;
}

.fnt2 {
  font-family: Roboto;
  font-size: medium;
  color: #80cfe6;
}

.fnt3 {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
}

@media screen and (max-width: 900px) {
  .imgAdd {
    width: 50%;
    height: 100%;
  }
}

.bubbless {
  border: 2px solid #80cfe6;
  border-radius: 50%;
  padding: 0px 0px;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  color: #80cfe6;
  background-color: white;
}

.bubble1 {
  border: 2px solid #80cfe6;
  border-radius: 50%;
  padding: 8% 5%;
  justify-content: center;
  align-items: center;
}

.fnttable {
  font-family: Roboto;
  font-size: Regular;
  color: #003766;
}

.parent-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-supplier-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  padding: 1rem 0 1rem 2rem;
}

.image-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
}

@media screen and (max-width: 350px) {
  .add-supplier-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
  }

  .image-heading {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 0 0;
  }
}

.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

.btnPage {
  background: #fafafa;
  border: 1px solid #fafafa;
  color: #7e7e7e;
  padding: 0 4px;
  text-decoration: none;
}

.btnPage-active {
  background-color: #daf4fa;
  border: 1px solid #daf4fa;
  border-radius: 50%;
  /* padding: 1px 8px; */
  /* padding: 0px 6px; */
  font-size: 15px;
  outline: none;
  color: #7e7e7e;
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;

}

.page-index-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.bubble1 {
  border: 2px solid #80cfe6;
  /* background-color: red; */
  border-radius: 50%;
  padding: 8% 5%;
  justify-content: center;
  align-items: center;
}

.fnt3 {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
}

.table-hover-client tbody tr:hover td {
  /* background: #daf4fa; */
  background: #daf4fa;
  filter: drop-shadow(0px 8px 25px #00376614);
}

.ArrowUpDown {
  transform: rotate(180deg);
}

.custom-outer {
  max-width: 1338px;
  margin: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.add-part-filter input[type="text"] {
  border: none;
  font-size: 13px;
  font-weight: 500;
}

.add-part-filter.col-10 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.add-part-filter .heading-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
}

.add-part-filter .heading-title .part-calc {
  display: flex;
  justify-content: space-between;
  flex: 0.7 1;
  text-align: center;
}


.add-part-filter .heading-title .heading {
  flex: 1 1;
  font-size: 16px;
  color: #80cfe6;
  font-family: Roboto;
}

.add-part-filter .heading-title .part-calc h4 {
  font-size: 16px;
  color: #80cfe6;
  flex: 1 1;
  margin: 0;
  padding: 0;
}

.add-part-filter .heading-title .part-calc .custom-radio {
  font-size: 16px;
  color: #c1c1c1;
  flex: 1 1;
}

.add-part-filter .heading-title .part-calc h3 {
  font-size: 16px;
  color: #c1c1c1;
  flex: 1 1;
}