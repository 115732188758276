.productHeading {
  color: #003766;
}

.sortclr {
  color: #80cfe6 !important;
}

.toggelsort {
  color: #003766 !important;
  cursor: pointer !important;
}

.productHeading h2 {
  font-size: 28px;
}

.tabel-heading th {
  color: #c1c1c1;
  font-weight: 300;
}

.icons {
  color: black;
  cursor: pointer;
}

table.table tr td {
  padding: 12px 11px;
}

.hidden {
  visibility: hidden;
}

.bubble {
  background-color: transparent;
  border-radius: 100px;
  border: 1px solid #80cfe6;
  color: #80cfe6;
  height: 30px;
  width: 30px;
  cursor: pointer;
  font-size: 15px;
}

.table-hover tbody tr:hover td {
  background: #daf4fa;
  filter: drop-shadow(0px 8px 25px #00376614);
}

.icons1 {
  /* margin-left: -22px; */
  margin-left: 7px;
  margin-right: 34px;
  cursor: pointer;
}

.icons2 {
  margin-left: -26px;
  cursor: pointer;
}

.fnt12 {
  font-family: Roboto;
  font-size: medium;
  cursor: pointer;
  color: #80cfe6 !important;
}

.ArrowRotate {
  transform: rotate(180deg);
}