.sideBar {
  background-color: #daf4fa !important;
  opacity: 6 !important;
  font-size: 20px !important;
  color: #daf4fa !important;
  width: 65px !important;
  /* display: none; */
  /* display: flex !important; */
  /* flex-direction: row !important; */
  position: fixed;
  box-shadow: 3px 0px 10px #00000029;
  /* position: absolute; */
}

.sidenav---navtext---1AE_f {
  color: #003766 !important;
  font-size: 18px;
  font-weight: 600;
  margin-left: 70px !important;
  width: 5px;
}

/* .active {
  color: red;
} */
.sidenav---navtext---1AE_f1 {
  color: #003766 !important;
  font-size: 18px;
  font-weight: 600;
  /* margin-left: 70px !important; */
  width: 5px;
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  display: block;
  width: 19px;
  height: 2px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 1px;
  transition: all 0.15s;
  /* opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70); */
}

.navRightIcon {
  /* font-size: 26px !important; */
  font-weight: 900 !important;
  background-color: #80cfe6;
  padding: 6px 9px;
  color: black !important;
  /* position: absolute; */
  border-radius: 50%;
  top: 10% !important;
  z-index: 100;
  box-shadow: 3px 0px 10px #00000029;
}

.navRightTrueIcon {
  /* font-size: 26px !important; */
  font-weight: 900 !important;
  background-color: #80cfe6;
  padding: 6px 9px;
  color: black !important;
  /* position: absolute; */
  border-radius: 50%;
  top: 10% !important;
  z-index: 100;
  box-shadow: 3px 0px 10px #00000029;
}

.rootContainer {
  /* position:absolute; */
  margin-left: 4.81%;
  width: 96%;
}

.rootContainerAcive {
  /* position:absolute; */
  margin-left: 15.8%;
  width: 85%;
}

.navIconBack {
  background-color: transparent !important;
}

.sideNavFont {
  font-size: 25px !important;
  color: #003766 !important;
}

.closeSidebar {
  display: none;
}

.navBarlogo {
  position: absolute !important;
  left: 25% !important;
  top: 0% !important;
}

.orderIcon {
  width: 18px;
  height: 17px;
}

.orderIcon1 {
  width: 18px !important;
  /* height: 17.98px !important; */
}

.iconClient {
  width: 22.96px;
  height: 19.46px;
}

.iconClient2 {
  width: 22.96px !important;
  /* height: 27px !important; */
}

.inventoryIcon {
  width: 22.63px;
  height: 26.75px;
}

.inventoryIcon2 {
  width: 22.63px !important;
  height: 34px !important;
}

.productsIcon {
  width: 20.13px;
  height: 27.89px;
}


.productsIcon2 {
  width: 20.13px !important;
  height: 34.89px !important;
}

.teamIcon {
  width: 22.79px;
  height: 15.9px;
}

.teamIcon2 {
  width: 22.79px !important;
  height: 25px !important;
}

.supplierIcon {
  width: 20px;
  height: 26.85px;
}


.supplierIcon2 {
  width: 20px !important;
  height: 34.85px !important;
}

.navBackg {
  /* background: #A9D1E0; */
  /* width: 60px;
  background-color: darkgrey; */

  text-decoration: underline;
  /* font-weight: bolder; */
  /* opacity: 0.5; */
  /* background: linear-gradient(to right, #efc169 50%, transparent 50%);
  transition: all .5s ;
  line-height: 0; */
}

.imgicon {
  /* text-decoration: underline; */
  /* border-bottom: red 10px solid; */
  /* width: 50px; */
}

.navBackg img {
  /* filter: brightness(0%); */
  width: 20px;
  height: 30px;
  padding-bottom: 5px;
  border-bottom: #063970 3px solid;
  /* filter: brightness(0); */
}

.navBackg .navText {
  /* filter: brightness(0%); */
  /* width: 20px;
  height: 40px;
  padding-bottom: 5px;
  border-bottom: #063970 3px solid; */
  /* font-size: 25px; */
  font-weight: 700;
  /* filter: brightness(0); */
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  /* opacity: 1; */
}

/* .new{
  opacity: 1;
} */

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T {
  position: relative;
  display: block;
  line-height: 50px;
  /* height: 29px; */
  height: 15px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  /* opacity: 1; */
}

/* .rotate {
  transform: rotate(-180deg);
} */
.btn1 {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #003766;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0rem;
  font-size: 1.1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .dropdown-item1 {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
} */
/* .dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  width: 10px;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
} */

.dropbtn {
  background-color: #daf4fa;
  color: black;
  /* padding: 16px;
  font-size: 16px; */
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  /* display: none; */
  position: absolute;
  right: 0;
  background-color: #daf4fa;
  /* min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

.dropdown-content:hover {
  z-index: 1;
}

.dropdown-content a {
  /* color: black; */
  /* padding: 12px 16px; */
  /* padding-bottom: 100px; */
  text-decoration: none;
  display: block;

  /* my css */
  color: #003766 !important;
  height: 20px;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  right: 30px;
  /* margin-right: 70px !important; */
  /* width: 5px; */
}

.dropdown-content a:hover {
  /* background-color: #daf4fa; */
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* .dropdown:hover .dropbtn {
  background-color: #3e8e41;
} */





.MainMenu::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

.MainMenu::-webkit-scrollbar {
  width: 10px;
}

.MainMenu::-webkit-scrollbar-thumb {
  background-color: #80cfe6;
}

.logoBarSK {
  position: absolute;
  top: -24px;
  left: 58px;
}