.orderIconPop {
  width: 40px;
  height: 40px;
}

.orderPopupHeadText {
  font-size: 17px;
  /* color: #003766; */
  color: rgb(96, 170, 198);
  ;
  margin-top: 8px;
}

.backgrnd {
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
}

.contname {
  font-size: 1rem;
  color: rgb(96, 170, 198);
}

.clienthead {
  font-size: 17px;
  color: #003766;
  font-weight: 500 !important;
}

.datetext {
  font-size: 15px;
  font-weight: 400;
  color: #b4b4b4;
}

.orderPopupHeadText1 {
  font-size: 16px;
}

.orderPopupHeadText2 {
  font-size: 19px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.moveBtn {
  background: #003766;
}

.moveBtn:hover {
  background: #003766;
}

.moveBtn:focus {
  background: #003766;
}

.popovermodal {
  position: fixed;
  margin-left: 55%;
  margin-top: 25%;
}

@media screen and (max-width: 575.9px) {
  .popovermodal {
    position: fixed;
    margin-left: 30%;
  }
}

.form-switch .form-check-input.ordcheck:checked {
  width: 64px;
  height: 32px;

  background-color: #daf4fa !important;
  cursor: pointer;
}

.form-switch .form-check-input.ordcheck {
  width: 64px;
  height: 32px;
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); */
  /* background-color: gray !important; */

  background-image: url(..//../assets//icons/greycrop1.svg);
  background-size: 25px 27px;
  cursor: pointer;
}

.checkbutton .form-check-input:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: transparent !important;
}

.teambtn {
  border-radius: 50%;
  cursor: default !important;
  width: 50px;
  height: 50px;
  border: none;
  color: #003766;
  background-color: #80cfe6;
  font-size: 18px;
  font-weight: 400;
}

.popovermodalCreate {
  position: fixed;
  margin-left: 45%;
  margin-top: 25%;
}

@media screen and (max-width: 575.9px) {
  .popovermodalCreate {
    position: fixed;
    margin-left: 20%;
  }
}

.teambtnOrder3 {
  border-radius: 50%;
  cursor: default;
  width: 2.5vw;
  height: 2.5vw;
  border: none;
  color: #003766;
  background-color: #80cfe6;
  font-size: 18px;
}

.carddata1 {
  visibility: hidden;
}

@media screen and (max-width: 767.98px) {
  .teambtnOrder3 {
    border-radius: 50%;
    cursor: default;
    width: 6vw;
    height: 6vw;
    border: none;
    color: #003766;
    background-color: #80cfe6;
    font-size: 18px;
  }
}

.orderIconPop2 {
  width: 35px;
  height: 35px;
  margin-left: 1.7%;
  margin-top: 3% !important;
}

.orderIconPop3 {
  width: 35px;
  height: 35px;
}

.installBtn {
  color: #023867;
  border: 2px solid #023867;
  font-weight: 700;
  background: #ffffff;
  border-radius: 10px !important;
}

.installBtn:hover {
  color: #023867;
  border: 2px solid #023867;
  font-weight: 700;
  background: #ffffff;
  border-radius: 10px !important;
}

.installBtn:focus {
  color: #023867;
  border: 2px solid #023867;
  font-weight: 700;
  background: #ffffff;
  border-radius: 10px !important;
}

.orderIconPop {
  width: 35px;
  height: 35px;
}

.specImg {
  width: 100%;
  margin-top: 17%;
  margin-left: 0%;
}

.specImg_pop {
  width: 100%;
  margin-top: 9%;
  margin-left: 0%;
}

.orderLableclr {
  color: rgb(96, 170, 198);
}

.contblck {
  font-size: 17px;
}

.orderInputclr {
  /* color: #003766; */
  color: #212121;
  font-weight: inherit !important;
  font-size: 16px !important;
}

.telphone {
  font-size: 15px;
  color: rgb(0, 55, 102);
}

.email {
  font-size: 15px;
  color: rgb(0, 55, 102);
  word-wrap: break-word;
}

.searchinput1 .wrapper {
  border: none !important;
  background-color: #fafafa !important;
  z-index: 1 !important;
}

.quoteId {
  border: none !important;
  max-width: 128px;
  outline: none;
}

.quoteId1 {
  border: 1px solid #ced4da;
  border-radius: 5px;
  max-width: 115px;
  outline: none;
}

.duedate {
  font-weight: normal;
  color: rgb(0, 55, 102);
}

.commit-title1 {
  /* width: 83%; */
  /* width: 29%; */
  align-items: center;
  margin: 0 auto;
}

.attachment label {
  display: unset;
}

[role=button] {
  cursor: default;
}

.commit-title1 h2 {
  font-size: 16px;
  color: #003766;
}

.commit-title1 p {
  font-size: 16px;
  color: #003766;
}

.commit-main1 {
  /* border-bottom: 1px solid #ddd; */
  width: 70%;
  margin: 0 auto;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.commit-description1 h2 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.commit-description1 p {
  font-size: 14px;
  margin: 0 0 5px 0;
}

.commit-description1 p a {
  text-decoration: none;
}

.commit-description1 {
  /* margin-left: 16px; */
  /* display: flex; */
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 0.5px solid #003766;
  color: #003766;
  font-size: 16px;
}

.comentlist {
  width: 94%;
}

.comment-section1 {
  background: #fff;
  display: flex;
  margin-left: -11px;
  margin-right: -11px;
  margin-top: 23px;
  border-radius: 0 0 10px 10px;
  margin-bottom: 70px;
}

.comment-section1 {
  margin-block: 30px;
}

.comment-section1 form {
  width: 80%;
  border-radius: 10px;
  border: 0.5px solid #003766;
}

.comment-section1 form input {
  /* width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 0.5px solid #003766;
  color: #b4b4b4;
  font-size: 16px; */
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  /* border: 0.5px solid #003766; */
  color: #b4b4b4;
  font-size: 16px;
}

.comment-section1 form input:focus-visible {
  outline: none;
  /* border-color:1px solid transparent; */
  /* border: none !important; */
}

.comment-section1 .sendBtn {
  background: #003766;
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.timeh5 {
  font-size: 13px;
  color: #b4b4b4;
  margin-bottom: 0px;
  font-weight: 400;
  line-height: 13px;
  text-align: right;
}

custom-ci-size1 {
  margin-top: 8px;
  width: 30px;
  height: 30px;
  font-size: 14px;
  margin-right: 7px;
}

.profileButton1 {
  border-radius: 50%;
  height: 43px;
  width: 43px;
  border: none;
  color: #003766;
  background-color: #daf4fa;
  font-size: 18px;
  font-weight: normal;
  margin-top: 4px;
  margin-right: 6px;
}

/* .dropdown-toggle1.profileButton1:hover {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: none;
  color: white;
  background-color: #80cfe6;
  font-size: 16px;
  font-weight: 600;
} */
.dropdown-toggle1.profileButton1:hover {
  color: #003766;
  background-color: #daf4fa;
}

.commentinput {
  width: 100%;
}

.partdiv {
  border-bottom: 1px solid #80cfe6;
}

.profileButton1 .commentinput:focus-visible {
  border: none;
  outline: none;
}

.headclr {
  color: rgb(0, 55, 102);
}

.drop-down-plus {
  position: absolute;
  width: 215px;
  height: 215px;
  background-color: white;
  left: 0px;
  bottom: 15px;
  border-radius: 4px;
  box-shadow: 0px 6px 12px #2d2e1c1a;
  text-align: center;
  max-height: 300px;
  overflow-y: scroll;
}

.search-people-input {
  width: 85%;
  border-radius: 8px;
  height: 100%;
  opacity: 50%;
  border: none;
}

.peoples-flex {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  cursor: pointer;
  justify-content: start;
  column-gap: 5px;
  align-items: center;
  margin-top: 5px;
}

.emoji-picker-style1 {
  position: absolute;
  left: -33px;
  bottom: 30%;
  display: inline-block;
}

/* .selectname option {
  background-color: red;
  height: 30px;
  overflow: auto;
} */

.backOrder-creat-order {
  background-color: #daf4fa;
  border-radius: 15px 15px 0px 0px;
  /* border: 2px 2px 0px 2px solid rgba(128, 128, 128, 0.414); */
  /* height: 90px; */
  height: 127px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* padding-left: 52px; */
}

.productnameHeader_pop {
  font-size: 22px;
  font-weight: bold;
  /* font-family: "Roboto, Black"; */
  color: #003766;
  line-height: 29px;
}

.productname_pop {
  /* text-align: center; */
  word-wrap: break-word;
  word-break: break-all;
  font-size: 16px;
  font-weight: bold;
  /* font-family: "Roboto, Bold"; */
  color: #003766;
}

/* .backOrder1 {
  background-color: #fded91;
  border-radius: 15px 15px 0px 0px;
  border: 2px 2px 0px 2px solid rgba(128, 128, 128, 0.414);
  height: 90px;
  display: flex;
  justify-content: space-between;
} */

/* .backOrder-creat-order img {
  height: 7vw;
} */


.select-dropdown-custom {
  /* border: 1px solid #ced4da; */
  /* border-radius: 0.25rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border {
  border: 2px solid #00000029 !important;
  border-radius: 8px;
}

.select-dropdown-custom .editTask {
  display: flex;
}

.select-dropdown-custom .editTask span {
  margin-top: 8px;
  margin-left: 13px;
  color: #003766;
  font-weight: 700;
}

.select-dropdown-custom .drop-custom .form-select {
  border: none;
  color: #003766;
  font-weight: 500;
  font-size: 16px !important;
}

.select-dropdown-custom .drop-custom .form-select:focus {
  box-shadow: none;
}

/* popup Start*/
.modalcontainer {
  --bs-modal-width: 640px;
}

/* popup End*/

.field-disable {
  pointer-events: none;

}