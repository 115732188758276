.backOrder {
  background-color: #daf4fa;
  border-radius: 15px 15px 0px 0px;
  border: 2px 2px 0px 2px solid rgba(128, 128, 128, 0.414);
  height: 90px;
  display: flex;
  justify-content: space-between;
}

.textAdjust {
  word-break: break-word;
  word-wrap: break-word;
  font-size: 16px;
  font-weight: bold;
  /* font-family: Roboto; */
  color: #003766;
  width: 80px;
  height: 20px;
}

.yellow-creat-order {
  background-color: #fded91;
  box-shadow: 0px 3px 12px #fded91;
  border-radius: 10px;
  border: 2px 2px 0px 2px solid rgba(128, 128, 128, 0.414);
  height: 127px;
  display: flex;
  justify-content: space-between;
}

.backOrder1 {
  background-color: #fded91;
  box-shadow: 0px 3px 12px #fded91;
  border-radius: 10px;
  border: 2px 2px 0px 2px solid rgba(128, 128, 128, 0.414);
  height: 129px;
  display: flex;
  justify-content: center;
}

.backOrder2 {
  background-color: #DAF4FA;
  box-shadow: 0px 3px 12px #00367740;
  border-radius: 10px;
  border: 2px 2px 0px 2px solid rgba(128, 128, 128, 0.414);
  height: 129px;
  display: flex;
  justify-content: center;
}


.OrderCalenIcon {
  width: 35px;
  height: 36px;
  position: relative;
  margin-bottom: 0px;
}

.calenderIcon {
  width: 35px;
  height: 36px;
}

.spclPart {
  color: #80CFE6;
}

.quoteIcon {
  width: 31px;
  height: 31px;
}

.clienticon {
  width: 34px;
  height: 29px;
}

.inputOrdercmnt {
  outline: none;
  height: 49px !important;
  width: 98%;
  /* border: 2px solid #00366653; */
  border: 0.5px solid #003766;
  border-radius: 10px;
}


.checkbutton .form-check-input:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: transparent !important;
}


.cardBorder1 {
  border-radius: 0px 0px 0px 0px !important;
  border-bottom: none !important;
  padding: 11px !important;
  /* height: 550px; */
}

.cardBorder2 {
  border-radius: 0px 0px 0px 0px !important;
  border-bottom: none !important;
  border-top: none !important;
  /* padding: 11px !important; */
  padding: 13px !important;
  /* height: 550px; */
  margin-top: -12px;
}

.cardBorder3 {
  border-radius: 0px 0px 15px 15px !important;
  border-top: none !important;
  padding: 11px !important;
  padding-bottom: 0px !important;
  margin-top: -12px;
  /* height: 550px; */
}


.addBtnOrder {
  width: 75px;
  height: 75px;
  position: absolute;
  margin-left: 42%;
  margin-top: -8%;
  background-color: #c1c1c1;
  color: #fff;
  border: none;
  font-weight: 700;
  font-size: 21px;
}


@media screen and (max-width: 991.98px) {
  .addBtnOrder {
    width: 75px;
    height: 75px;
    position: absolute;
    margin-left: 42%;
    margin-top: -13%;
    background-color: #c1c1c1;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 21px;
  }
}


@media screen and (max-width: 767.98px) {
  .addBtnOrder {
    width: 75px;
    height: 75px;
    position: absolute;
    margin-left: 42%;
    margin-top: -8%;
    background-color: #c1c1c1;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 21px;
  }
}


@media screen and (max-width: 575.98px) {
  .addBtnOrder {
    width: 75px;
    height: 75px;
    position: absolute;
    margin-left: 42%;
    margin-top: -13%;
    background-color: #c1c1c1;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 3vh;
  }
}


.hold {
  cursor: pointer;
  background-color: #daf4fa;
}

.hold1 {
  cursor: pointer;
  background-color: #fded91;
}


.imgOrder {
  /* width: 90px; */
  /* height: 50px; */
  width: 70px;
  height: 70px;
}

.css-tj5bde-Svg {
  /* display: inline-block; */
  /* display: none !important; */
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

/* ASC SHOW START */
.css-1u9des2-indicatorSeparator {
  /* display: none !important; */
  width: unset !important;
}

/* ASC SHOW END */


.fntClr {
  color: #003766;
}


.teambtnOrder {
  border-radius: 50%;
  cursor: default;
  width: 80px;
  height: 80px;
  border: none;
  color: #003766;
  background-color: #80cfe6;
  font-size: 18px;
  padding-top: 25px;
  font-weight: bold;
  font-size: 18px;
  cursor: move;
}

.emoji-picker-style {
  position: absolute;
  left: 200px;
  bottom: 200%;
  display: inline-block;
}


.teamavatar {
  border-radius: 50%;
  cursor: default;
  width: 80px;
  height: 80px;
  border: none;
  color: #003766;
  font-size: 14px;
  font-weight: bold;
  cursor: move;
}

.teamavatar img {
  height: 80px;
  width: 80px;
  border-radius: 50px;
}


@media screen and (max-width: 1199.98px) {
  .teambtnOrder {
    border-radius: 50%;
    cursor: default;
    width: 80px;
    height: 80px;
    border: none;
    color: #003766;
    background-color: #80cfe6;
    font-size: 15px;
  }
}

.orderPopupHeadText9 {
  color: #003766;
  font-size: 17px;
}

.orderPopupHeadText8 {
  color: #b4b4b4;
  font-size: 14px;
}

.orderPopupHeadText7 {
  color: #7e7e7e;
  font-size: 14px;
}

.teambtnOrder2 {
  border-radius: 50%;
  cursor: default !important;
  width: 3vw;
  height: 3vw;
  border: none;
  color: #003766;
  background-color: #80cfe6;
  font-size: 14px;
}

.teambtnOrder3 {
  /* border-radius: 50%; */
  cursor: pointer !important;
  /* width: 4vw;
  height: 4vw; */
  border: none;
  color: #003766;
  /* background-color: #80cfe6; */
  background-color: #DAF4FA;
  font-size: 14px;


  width: 50px;
  height: 50px;
  border-radius: 34px;
}


@media screen and (max-width: 999.98px) {
  .teambtnOrder2 {
    border-radius: 50%;
    cursor: default !important;
    width: 5vw;
    height: 5vw;
    border: none;
    color: #003766;
    background-color: #80cfe6;
    font-size: 15px;
  }
}


.minpluBtn {
  /* border:3px solid #000000 !important;  */
  color: #003766;
  cursor: pointer;


  /* background-color: #DAF4FA; */
}


.movCardd {
  margin-left: -39%;
}


.addBtnOrderActive {
  width: 75px;
  height: 75px;
  position: absolute;
  margin-left: 42%;
  margin-top: -8%;
  background-color: #003766;
  color: #fff;
  border: none;
  font-weight: 700;
  font-size: 21px;
}


@media screen and (max-width: 991.98px) {
  .addBtnOrderActive {
    width: 75px;
    height: 75px;
    position: absolute;
    margin-left: 42%;
    margin-top: -13%;
    background-color: #003766;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 21px;
  }
}


@media screen and (max-width: 767.98px) {
  .addBtnOrderActive {
    width: 75px;
    height: 75px;
    position: absolute;
    margin-left: 42%;
    margin-top: -8%;
    background-color: #003766;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 21px;
  }
}


@media screen and (max-width: 575.98px) {
  .addBtnOrder {
    width: 75px;
    height: 75px;
    position: absolute;
    margin-left: 42%;
    margin-top: -13%;
    background-color: #003766;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 3vh;
  }
}


.productremove {
  cursor: pointer;
  color: red;
  font-size: 15px;
  font-weight: bold;
}


.ordercancelbtn {
  height: 60px;
  background-color: transparent;
  color: #003766;
  width: 100%;
  margin-top: -1px;
  padding-top: 28px;
  padding-bottom: 48px;
  font-size: 17px;
  border: none;
  border-right: 1px solid #003766;
}

.orderaddbtn {
  height: 60px;
  background-color: transparent;
  color: #003766;
  width: 100%;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

.orderaddbtn1 {
  height: 60px;
  background-color: transparent;
  color: gray;
  width: 100%;
  padding-top: 28px;
  padding-bottom: 48px;
  margin-top: -1px;
  font-size: 17px;
  border: none;
}

.footerline {
  border-top: 1px solid #003766 !important;
}

.choosebtn {
  width: 181px;
  height: 49px;
  border: 1px solid #003766;
  border-radius: 14px;
  padding-right: 5px;
  padding: 0px 28px;
  cursor: pointer;
  opacity: 1;
  font: normal normal medium 18px/24px Roboto;
  letter-spacing: 0px;
  color: #003766;
  opacity: 1;
  background-color: transparent;
}

.headclr {
  color: rgb(0, 55, 102);
}

.clientinput {
  border: none;
  background-color: #fafafa;
  width: 354px;
}

.clientinput1 {
  border: none;
  background-color: #fafafa;
  /* width: 165px; */
}


.location {
  border: none;
  border-radius: 14px;
  opacity: 1;
  font: normal normal normal 15px/24px "Roboto";
  letter-spacing: 0px;
  color: #003766;
  opacity: 1;
  background-color: transparent;
  padding-left: 2px;
}

.location::placeholder {
  color: #003766;
}

.searchinput .wrapper {
  border: none !important;
  background-color: #fafafa !important;
}

.cardheight {
  height: 129px !important;
}

.clientselect .form-select {
  color: #003766 !important;
}


.clientselect .form-select:focus {
  border-color: #003766;
  outline: 0;
  box-shadow: none;
}

.checkbutton .form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.searchinput svg {
  display: none;
}

.searchinput .bdHMCv>.wrapper {
  border-radius: 10px !important;


  font-size: 20px !important;
  font-weight: 500 !important;
  color: #003766 !important;
}

.searchinput .pHieT>div.line {
  margin-left: 0px;
}

.searchinput .jMcrRR>input {
  color: #003766;
}


element.style {
  font-size: 20px;
  color: rgb(0, 55, 102);
}

.emailform .form-control[readonly] {
  background-color: #fafafa;
  opacity: 1;
}

div.css-yk16xz-control {
  color: #000000;
  background-color: transparent;
  border-style: none;
  /* border-bottom: 2px solid #bfbfbf; */
  border-radius: 0;
}

.Address .css-2b097c-container {
  width: 100%;
}


.Installation .form-select {
  background-image: none;
}

.Installation .form-select:focus {
  border-color: #003766;
  outline: 0;
  box-shadow: none;
}

.Address .css-tlfecz-indicatorContainer svg {
  display: none;
}

.Address .css-tlfecz-indicatorContainer svg:target {
  display: none;
}

.Address .css-tlfecz-indicatorContainer svg:visited {
  display: none;
}

.Address .css-tlfecz-indicatorContainer svg:visited {
  display: none;
}

/* .form-switch .form-check-input:focus {
  background-image: url();
} */

.form-switch .form-check-input:checked {
  background-position: right center;
  background-color: transparent;
  background-size: 25px 27px;

  background-image: url("..//../assets/icons/bluecrop1.svg");
  /* background-size: 20px 20px; */

  /* background-image:; */
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e); */
}

.Driverrole {
  border: 6px solid red;
}

.Mechanicrole {
  border: 6px solid blue;
}

.placeholderContact ::placeholder {
  font-size: 15px;
}


.color12 {
  /* background-color: #80cfe6; */
  background-color: #DAF4FA;
  font-size: 14px;
  width: 80px;
  height: 80px;
  border-radius: 100px;
  border: 6px solid;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.color13 {
  margin-top: 10px;
  font-size: 24px;
}

.sendbtn2 {
  right: 80px !important;
  margin-top: -30px !important;
}

.placeholderContact input {
  font-size: 16px !important;
}

.placeholderContact ul {
  font-size: 16px !important;
}

.hCpHso>div.line {
  margin-left: 0px !important;
}

.dot-text-adjust {
  white-space: nowrap;
  width: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 1px solid #000000; */
}

.placeholderContact ul {
  overflow-y: scroll;
  display: block;
  height: 14.5rem;
}

.productnameHeader {
  font-size: 22px;
  font-weight: bold;
  /* font-family: "Roboto, Black"; */
  color: #003766;
  line-height: 29px;
}

.productname {
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 16px;
  font-weight: bold;
  /* font-family: "Roboto"; */
  color: #003766;
}

.asc-custom-container {

  position: absolute !important;
  z-index: 1;
  width: 99%;
  border: 0.5px solid #003766 !important;
  font-size: 16px;
  border-radius: 10px;
  background-color: #fafafa;
  padding: 0;
}

.asc-custom-container-contact-name {
  
  position: absolute !important;
  /* z-index: 1; */
  width: 99%;
  border: 0.5px solid #003766 !important;
  font-size: 16px;
  border-radius: 10px;
  background-color: #fafafa;
  padding: 0;
}

.contctName {
  color: #80CFE6;
}

.cmyNjn>.wrapper {
  border-radius: 10px !important;
}

.orderProductText {
  font-size: 17px;
}

.teambtn_order {
  border-radius: 50%;
  cursor: default !important;
  width: 50px;
  height: 50px;
  border: none;
  color: #003766;
  /* background-color: #DAF4FA; */
  font-size: 18px;
  font-weight: 400;
}



.asc-custom-container .sk-custom-select__single-value {
  background: none !important;
  color: #003766 !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}

.overlap .react-datepicker-popper {
  z-index: 2;
}

/* .greyCircle #flexSwitchCheckChecked{
  background-image: url("..//../assets/icons/greyCrop.svg");
 } */



.custom-radio-check-sk {
  font-size: 14px;
  color: #c1c1c1;
  flex: 1 1;
  display: flex;
  justify-content: end;
  align-items: center;
}

.custom-radio-check-sk input[type="checkbox"] {
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid currentColor;
  transform: translateY(-0.075em);
  place-content: center;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-radio-check-sk input[type="checkbox"]::before {
  content: "";
  width: 0.85em;
  height: 0.85em;
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #003766;
  border-radius: 15px;
}

.custom-radio-check-sk input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.customSelect_Outer:hover {
  background-color: #fafafa;
  color: black;
  cursor: pointer;
  position: relative;
}

.customSelect_Outer {
  text-align: left;
  background-color: #fafafa;
  position: relative;
}

.customSelect_Outer2 {
  text-align: left;
  background-color: #fafafa; 
  position: relative;
  border-radius: 0px 0px 10px 10px;
  border: 2.5px solid #000000 !important;
  margin-left: 26px;
  margin-right: 4px;
  border-top: none !important;
}

.customSelect {
  padding-left: 10px;
  margin: 0;
  border-radius: 0px 0px 10px 10px;
}

.customSelect:hover {
  background-color: lightgray;
  color: #000000;
  cursor: pointer;
}

.contact_name_drop {
  width: 370px;
  height: 42px;
  position: relative;
  border-radius: 10px;
  background-color: #fafafa;
  border: none;
  /* border-bottom: hidden !important; */
}

.contact_name_drop2 {
  border-radius: 10px 10px 0px 0px;
  width: 370px;
  height: 42px;
  position: relative;
  background-color: #fafafa;
  /* border: none; */
  border-bottom: hidden !important;
}

.contact_name_drop:focus {
  border: 0.5px solid red !important;
  border-bottom: hidden !important;
}