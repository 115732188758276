.outer {
  background-color: #fafafa;
  width: 80%;
  height: 100%;
  align-items: center;
}

.newcosthour {
  border: none;
  background: unset;
  pointer-events: none;
}

.Add1 {
  max-width: 97px;
  max-height: 97px;
  cursor: pointer;
}

.tableWrapper {
  background: #fafafa;
  padding: 0 0 10px 0;
  /* margin: 30px 124px; */

  /* max-width: 80%; */
  align-items: center;
  position: relative;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

@media screen and (max-width: 1225px) {
  .tableWrapper {
    background: #fafafa;
    /* padding: 20px 0; */
    /* margin: 30px 50px; */

    /* max-width: 80%; */
    align-items: center;
    position: relative;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

/* table.table tr th:first-child {
    max-width:151px;
    max-width: 80%;
  }
  table.table tr th:last-child {
    max-width: 150px; 
     max-width: 80%;
  } */
.fnt1 {
  font-family: Roboto;
  font-size: medium;
  color: #c1c1c1;
}

.fnt2 {
  font-family: Roboto;
  font-size: medium;
  color: #80cfe6;
  cursor: pointer;
}

.fnt3 {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
}

@media screen and (max-width: 900px) {
  .imgAdd {
    width: 50%;
    height: 100%;
  }
}

.bubble {
  border: 2px solid #80cfe6;
  /* background-color: red; */
  border-radius: 50%;
  padding: 3px 4px;
  justify-content: center;
  align-items: center;
}

.bubble1 {
  border: 2px solid #80cfe6;
  /* background-color: red; */
  border-radius: 50%;
  padding: 8% 5%;
  justify-content: center;
  align-items: center;
}


.addUsericon {
  text-align: center;
  background-color: #003766;
  width: 97px;
  height: 97px;
}

.fnttableuser {
  font-family: Roboto;
  font-size: Regular;
  color: #003766;
}

.fnttableuser1 {
  font-family: Roboto;
  font-size: Regular;
  color: #C1C1C1;
}


.parent-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-supplier-row {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
  justify-content: flex-start !important;
  padding: 1rem 0 1rem 2rem !important;
}

.user-add-supplier-row {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
  justify-content: flex-start !important;
  padding: 1rem 0 1rem 0rem !important;
}

.image-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
}

.user-image-heading {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  padding-left: 1rem !important;
}

@media screen and (max-width: 350px) {
  .add-supplier-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    /* padding: 1rem 0 1rem 2rem; */
  }

  .image-heading {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 0 0;

    /* align-self: center;
      justify-content: center; */
  }
}

.parent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnPage {
  background: #fafafa;
  border: 1px solid #fafafa;
  color: #7e7e7e;
}

.btnPage-active {
  background-color: #daf4fa;
  border: 1px solid #daf4fa;
  border-radius: 50%;
  padding: 0px 8px;
  font-size: 15px;
  outline: none;
  color: #7e7e7e;
}

.page-index-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  /* padding: 1rem; */
}

.modal-backdrop.show {
  opacity: 0.3;
  background-color: #003766;
}

.table.table tr td.cost12 {
  padding: 12px 11px;
}

.table.table tr td.cost13 {
  padding: 12px 34px;
}

.ArrowUpDown13 {
  transform: rotate(180deg);
}