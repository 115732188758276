.textblue {
  color: #69389d;
}

.newlogin {
  color: #d86363;
  font-family: "Poppins";
  font-size: 11px;
  position: absolute;
  position: relative;
  width: 70%;
  z-index: 10000;
  background: none;
}

.checksection1 {
  margin-top: 15px;
  margin-left: 1px;
  margin-bottom: 10px;
}

.line1 {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #e1e1e1;
  border-color: #e1e1e1;
  line-height: 0.1em;
  margin: 10px 0 10px;
  font-family: "Poppins";
  font-size: 14px;
}

.linkstyle {
  text-decoration: none;
  color: #003766;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 500;
}

.form-check-input.blueCheck:checked {
  background-color: darkblue;
  border-color: darkblue;
}
