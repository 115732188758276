.columnsbg {
  background-color: #ffffff;
  /* width: 280px; */
  min-height: 570px;
  max-height: 570px;
  width: 280px;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 15px;
}

.columnsheader {
  font-size: 25px;
  font-family: "Roboto";
  letter-spacing: 0px;
  color: #003766;
  opacity: 1;
  font-weight: 700;
}

.addBtn {
  border-radius: 50%;
  cursor: default;
  width: 25px;
  height: 25px;
  border: none;
  color: #fff;
  background-color: #003766;
  font-size: 15px;
  display: flex;
  justify-content: center;
}


.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: none !important;
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}