.box-contactName {
  background-color: #fafafa;
  padding: 0px 15px;
  padding-bottom: 2px;
  border-radius: 10px;
}

.signtogbtn1 {
  border: #9fb2bc;
  color: #003766;
  background-color: #daf4fa;
  font-weight: 500;
  border-radius: 10px;
  /* width: 6.46vw; */
  width: 98%;
  /* font-size: 18px; */
  font-size: calc(18px + 0%);
  box-shadow: 0px 6px 12px #00376629;
}

.signtogbtn {
  color: #003766 !important;
  width: 6.46vw;
  /* font-size: 18px !important; */
  font-size: calc(18px + 0%);
  /* font-size: calc(20% + 1vw); */
  font-weight: 500;
  border: none;
  background: transparent;
}

.eyeicon {
  height: 11px;
  width: 25px;
  position: relative;
  left: 92%;
  bottom: 28px;
  cursor: pointer;
}

.fntSupp1 {
  width: 100%;
  font-family: Roboto;
  font-size: Regular;
  color: #80cfe6;
}

.menuControl {
  border: #bfbfbf 2px solid;
  border-radius: 10px;
  width: 91%;
  margin-top: -3rem;
}

.box-contactName input {
  background-color: #fafafa !important;
  border: none;
  outline: none;
  width: 100%;
}

.fntSupp12 {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
  margin-left: 15px;
}

.fntSupp3 {
  font-family: Roboto;
  /* font-size: 22px; */
  color: #80cfe6;
}

.vacations {
  font-size: 15px;
}

.numcolor {
  color: #003766;
  font-weight: 500;
  text-align: center;
}

.fntSupp {
  width: 100%;
  font-family: Roboto;
  font-size: Regular;
  color: #003766;
}

.Availabilitybox {
  display: flex;
  justify-content: space-between;
}

/* .form-check-input{
  width: 30px;
 
  height: 50px;
} */

/* .switchtoggle{
  width: 50px;
  height: 30px;
} */

/* .form-switch .form-check-input{
  width: 50px;
  height: 30px;
} */

.form-check-input:checked {
  background-color: #daf4fa;
  border-color: #daf4fa;
}

.dlticon {
  width: 20px;
}

/* .box-contactName1{
  width: 110px;
  background-color: #fafafa;
  padding: 0px 10px;
  border-radius: 5px;
}   */

/* @media screen and (max-width: 357px) {
    .fntSupp {
      font-family: Roboto;
      font-size: 20px;
      color: #80cfe6;
    }
  } */

.fntSupp1 {
  font-family: Roboto;
  font-size: 18px;
  color: #80CFE6;
}

.addperiod {
  font-family: Roboto;
  /* font-size: 22px; */
  color: #003766;
}

.fntSupp45 {
  font-family: Roboto;
  font-size: Regular;
  color: #003766;
  height: 10px;
}

.fntSupp2 {
  font-family: Roboto;
  font-size: 28px;
  color: #003766;
}

@media screen and (max-width: 327px) {
  .fntSupp2 {
    font-family: Roboto;
    font-size: 22px;
    color: #003766;
  }
}

.notesForm1 {
  border: 1px solid #80cfe6;

  width: 110%;
  /* height: 10%; */
}

.notes1 {
  font-family: Roboto;
  font-size: 20px;
  color: #80cfe6;
}

.suppInput {
  border: 1px solid #00000029;
  border-radius: 8px;
  padding: 10px;
  height: 35px;
}

/* .pagination1 {
      float: right;
      margin: 0 0 5px;
  }
  .pagination1 li a {
      border: none;
      font-size: 13px;
      min-width: 30px;
      min-height: 30px;
      color: #999;
      margin: 0 2px;
      line-height: 30px;
      border-radius: 50% !important;
      text-align: center;
      padding: 0 6px;
  }
  .pagination1 li a:hover {
      color: #666;
  }
  .pagination1 li.active a, .pagination li.active a.page-link {
      background: #80CFE6;
  }
  .pagination1 li.active a:hover {        
      background: #80CFE6;
  }
  .pagination1 li.disabled i {
      color: #FAFAFA;
  }
  .pagination1 li i {
      font-size: 16px;
      padding-top: 6px
  } */
.countBtnchange1 {
  border: 1px solid #003766;
  /* background-color: transparent; */
  background-color: #003766;
  /* color: gray; */
  color: white;
  font-weight: 650;
  font-size: 15px;
  border-radius: 10px;
  /* height: 45px; */
}

/* ASC */
.addperiod1 {
  /* border: 1px solid #003766; */
  /* background-color: transparent; */
  color: gray;
  border: none;
  background: none;
  /* font-weight: 650;
  font-size: 15px;
  border-radius: 10px; */
}

/* ASC */
.addperiod2 {
  font-family: Roboto;
  /* font-size: 22px; */
  color: #003766;
  border: none;
  background: none;
}

.Mechanicimgborder {
  border: 7px solid #003766;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.Mechaniconsiteimgborder {
  border: 7px solid cyan;
}

.driverimgborder {
  border: 7px solid orange;
}

.countProicon1 {
  width: 180px;
}

.userimg {
  width: 40%;
}

.jobtitle {
  color: var(--unnamed-color-003766);
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #003766;
  opacity: 1;
}

.availabilitybox {
  border: 2px solid #00000029;
  border-radius: 8px;
  padding: 10px;
  /* height: 35px; */
}

.signUpldbtn {
  width: 160px;
  height: 160px;
  /* width: 8vw;
  height: 8vw; */
  /* UI Properties */
  /* background: transparent url('img/Ellipse 25.png') 0% 0% no-repeat padding-box; */
  border: 1px dashed #707070;
  opacity: 1;
  border-radius: 50%;
  /* background-color: white; */
  color: #bfbfbf;
  /* ASC */
  /* margin-bottom: -10px; */
}

.menuCont {
  border: #bfbfbf 2px solid;
  border-radius: 40px;
  width: 60vw;
}

.menuCont {
  border: #bfbfbf 2px solid;
  border-radius: 40px;
  width: 45vw;
}

.selectmenu {
  border: 1px solid #003766;
  border-radius: 10px;
  width: 70%;
}

.signtogbtn {
  color: #7e7e7e;
  width: 6.46vw;
  /* font-size: calc(20% + 1vw); */
  font-size: calc(18px + 0%);
  border: none;
  background: transparent;
}

/* Checkbox checked effect */
.checkbox:checked+.toggle::after {
  background-color: #003766;
  /* color: #000; */
  left: 31px;
}

/* Checkbox checked toggle label bg color */
.checkbox:checked+.toggle {
  background-color: #daf4fa;
}

/* Checkbox vanished */
.checkbox {
  display: none;
}

.textClrSign {
  color: #7e7e7e;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  /* background-color:#F1F1F1; */
  border-radius: 30px;
  border: 1px solid #9fb2bc;
}

.toggle:after {
  content: "";
  position: absolute;
  width: 27px;
  height: 29px;
  border-radius: 50%;
  background-color: #e6e6e6;
  top: -1px;
  left: 0px;
  transition: all 0.5s;
}

.imgdelete {
  cursor: pointer;
  /* float: right; */
}

.custom-file-input {
  color: transparent;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  color: #a3a3a3;
  display: inline-block;

  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* .jobcircale{
 
} */
.red-color:checked {
  /* background-color: transparent !important; */
  border-color: #003766;
  background-color: #003766;
  /* border-color: #0d6efd; */
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.blue-color:checked {
  /* background-color: transparent !important; */
  border-color: cyan;
  background-color: cyan;
  /* border-color: #0d6efd; */
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.orange-color:checked {
  /* background-color: transparent !important; */
  border-color: orange;
  background-color: orange;
  /* border-color: #0d6efd; */
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.grey-color:checked {
  /* background-color: transparent !important; */
  border-color: grey;
  background-color: grey;
  /* border-color: #0d6efd; */
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.jobcircale:focus {
  box-shadow: none;
}

.updateredio:checked {
  border-color: #003766;
  background-color: #003766;
}

.deletebtn {
  border: none;
  background: url("../../assets/icons/Group62.svg") no-repeat top left;
  /* padding: 2px 8px; */
  width: 27px;
  height: 27px;
}

.notes {
  height: 7.7rem;
}

.Mechanicimgborder {
  border: 7px solid #003766;
}

.hide {
  display: none;
}

.Mechaniconsiteimgborder {
  border: 7px solid cyan;
}

/* .driverimgborder {
  border: 7px solid ;
} */
.default-img-border {
  border: 7px solid grey;
}

.countBtncanc {
  font-size: 15px;
  color: #003766;
  font-weight: 500;
}

.stckFont {
  font-size: 28px;
}

.fntSupp28 {
  font-family: Roboto;
  font-size: 22px;
  color: #003766;
}

.errormsg1 {
  color: #d86363;
  font-family: "Poppins";
  font-size: 11px;
  position: absolute;
  margin-top: 30px;
}

@media screen and (max-width: 1200px) {
  .signtogbtn {
    width: 8.46vw;
  }

}

@media screen and (max-width: 864px) {
  .signtogbtn {
    width: 20.46vw;
  }

}

@media screen and (max-width: 992px) {
  .signUpldbtn {
    width: 18vw;
    height: 18vw;
  }

}

@media screen and (max-width: 575px) {
  .teamdaddres {
    padding-left: 23% !important;
  }

}

.calenOrder {
  height: 35px;
  margin-bottom: 0;
  position: relative;
  width: 35px;
}