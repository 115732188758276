.wareimgInv {
  height: 100px;
}

.PurchaseOrder2 {
  width: 63px;
  height: 45px;
}

.warehouse2 {
  width: 63px;
  height: 75px;
}

.stockavailable {
  width: 34px;
  height: 40px;
}

.commtxtsize {
  font-size: 18px;
}

.advised-section .advised-contents h5 {
  font-size: 20px;
}

@media screen and (max-width: 767.9px) {
  /* .stockavInv {
        width: 45vw;
    } */

  .card-title {
    padding-top: 8px;
  }

  .calInv {
    width: 8vw !important;
  }

  .homeCardLastcol .stock-avail .stock-avail-title {
    width: 100% !important;
  }

  .purchasingOrder-section .sort-section {
    width: inherit;
  }
}

@media screen and (max-width: 991px) {
  .purchasingOrder-section .sort-section {
    width: inherit !important;
    margin: 10px 0;
  }
}

.calInv {
  width: 2vw;
}

.shelInv {
  width: 3vw;
  padding-bottom: 8px;
}

.inventory .tableHover:hover {
  background-color: #daf4fa;
}

.inventory .fntClr {
  color: #003766;
}

.inventory .textDeco {
  /* text-decoration: underline; */
  cursor: pointer;
  padding: 0 !important;
  margin: 0 !important;
}

.inventory table.table tr td {
  padding: 10px;
  padding-bottom: 0;
}

.inventory .inventory-table {
  margin: 0;
}

.homeBox {
  font-size: 30px;
}

.homeCards {
  color: #003766;
  /* box-shadow:2px 2px ; */
  border-radius: 10px;
  background-color: white;
}

.homeCardDigit {
  /* font-size: 18px; */
  color: #80cfe6;
  font-weight: 700;
}

.homeCardText {
  font-size: 16px;
  color: #003766;
  font-weight: 500;
}

.homeCardLastcol {
  background-color: #fafafa;
  box-shadow: 0px 5px 15px #00376640;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 15px;
}

.homeCardLastcol .stock-avail {
  align-items: center;
  justify-content: center;
  display: flex;
}

.homeCardLastcol .stock-avail img {
  height: 4rem;
  /* width: 100%; */
  color: #003766;
}

.homeCardLastcol .stock-avail h5 {
  font-weight: bold;
}

.homeCardLastcol-advised {
  background-color: #e6e6e680;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 5px 15px #00376640;
}

.homeCardLastcol-advised .stock-avail {
  display: flex;
  padding: 0 6px;
}

.homeCardLastcol-advised .stock-avail img {
  height: 4rem;
  /* width: 100%; */
  color: #003766;
}

.homeCardLastcol-advised .stock-avail .show_more2 {
  font-size: 14px;
  cursor: pointer;
  text-align: end !important;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: #003766;
}

/* purchasingOrder section start */
.purchasingOrder-section {
  margin-top: 5rem;
}

.purchasingOrder-section .sort-section {
  width: calc(100% - 70%) !important;
}

.purchasingOrder-section .sort-section .sort-order {
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
}

.purchasingOrder-section .sort-section .date-order {
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.purchasingOrder-section .sort-section .date-order .custom-select:focus {
  outline: none;
}

.purchasingOrder-section .sort-section .date-order .custom-select option {
  border: none;
  color: #003766;
}

.purchasingOrder-section .sort-section .date-order .custom-select {
  appearance: none;
  background: transparent;
  border: 0;
  /* border-bottom: 2px solid #003766; */
  /* font-weight: bold; */
  padding: 0.125rem 1.25rem 0 0;
  font-size: 14px;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.25rem bottom 0px;
  background-size: 15px 15px;
  color: #003766;
}

.purchasingOrder-section .sort-section .date-order .asc {
  transform: rotate(180deg);
}

.purchasingOrder-section .sort-section .sort-order img {
  transform: rotate(90deg);
  height: 5px;
}

.purchasingOrder-section .sort-section .sort-order .asc {
  transform: rotate(270deg);
}

.purchasingOrder-section .fntClr tr td {
  padding: 0;
}

.purchasingOrder-section .fntClr h6 {
  margin: 0;
  font-weight: bold;
}

.purchasingOrder-section .fntClr td .w-max-content {
  width: max-content;
  gap: 10px;
  font-size: 22px;
  line-height: 29px;
  color: #003766;
  font-weight: 500;
}

/* purchasingOrder section end */

.advised-section .advised-heading p {
  color: #80cfe6;
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
}

.advised-section .advised-contents p {
  font-size: 1rem;
  margin: 0;
  word-break: break-all;
  width: 70%;
  text-align: left;
}

.advised-section .advised-contents h6 {
  font-size: 1.3rem;
  font-weight: bold;
  width: 30%;
  text-align: end;
}

.stock-avail .stock-avail-title-in {
  width: 65% !important;
}

.inventory .inventory-card .card-body {
  width: 80%;
}

@media screen and (max-width: 1440px) {
  .inventory .fntClr h6 {
    font-size: 18px !important;
  }

  .inventory .fntClr td .w-max-content h5 {
    font-size: 20px !important;
  }

  .inventory .fntClr td .w-max-content {
    font-size: 20px !important;
  }

  .inventory .purchase-title {
    font-size: 20px;
  }

  .inventory .card-body .card-title {
    font-size: 18px;
  }

  .inventory .sort-section {
    width: calc(100% - 65%) !important;
  }

  .inventory .homeCardText {
    font-size: 15px;
  }

  .inventory .custom-justify-table {
    justify-content: unset !important;
  }

  .inventory .stock-avail .stock-avail-title-in {
    /* width: 100% !important; */
  }

  .inventory .homeCardLastcol-advised .stock-avail .show_more {
    text-align: end !important;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.show_more_stock {
  cursor: pointer;
  text-align: end !important;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 14px;
  margin-left: -12px;
}

.arrivingtext1 {
  font-size: 15px !important;
  color: #c1c1c1;
}

.arrivingtext2 {
  color: #c1c1c1;
}

.plussSign {
  color: #c1c1c1;
  width: 13px;
  height: 16px;
}

.new {
  --bs-columns: 10;
  --bs-gap: 1rem;
}

@media screen and (max-width: 575px) {
  .inventory .inventory-card .card-body {
    width: 105%;
    padding-left: 6%;
  }

}

@media screen and (min-width: 2100px) {
  .wallres {
    width: 19%;
  }
}

.table-responsive {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}