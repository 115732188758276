@media screen and (max-width: 575px) {
    .resPagination {
        position: relative !important;
        left: 58% !important;
    }
}

@media screen and (max-width: 765px) {
    .resPagination {
        position: relative !important;
        left: 79.5% !important;
    }
}