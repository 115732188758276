.createordermodal {
  background-color: transparent;
  /* position: fixed;
  margin-left: 40%;
  margin-top: -4%; */
}

.createordermodal .modal-content {
  background-color: #003766;
  border-radius: 15px;
  color: white;
}
.createordermodal .modalfooter {
  border-radius: 0 0 15px 15px !important;
  height: 50px;
  padding-top: 8px !important;
}
.createordermodal .modal-footer {
  display: block;
  margin: -1px;
}
.createordermodal .nobtn {
  height: 49px;
  background-color: transparent;
  color: #ffffff;
  width: 100%;
  margin-top: -20px;
  font-size: 17px;
  border: none;
  border-right: 1px solid #ffffff;
}
.createordermodal .addbtn {
  height: 49px;
  background-color: transparent;
  color: #ffffff;
  width: 100%;
  margin-top: -20px;
  font-size: 17px;
  border: none;
}
