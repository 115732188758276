/* .popovermodal1 {
  position: fixed;
  margin-left: 28%;
  margin-top: 17%;
} */

.popovermodal11 {
  position: fixed;
  margin-left: 40%;
  margin-top: 17%;
}

.popheaderbg {
  background: #ff4868 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  width: 100%;
  /* margin-left: 1px; */
  margin-top: -1px;
  /* max-height: 100%; */
  border-radius: 15px 15px 0px 0px;
}

.popheaderbg1 {
  background: #ff4868 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  width: 101%;
  margin-left: -1px;
  margin-top: -1px;
  /* max-height: 100%; */
  border-radius: 15px 15px 0px 0px;
  border-bottom: 1px solid black;
}

.new {
  border-radius: 0px 0px 10px 10px;
}

.popcancelbtn {
  height: 50px;
  background-color: transparent;
  color: #003766;
  width: 100%;
  margin-top: -1px;
  font-size: 17px;
  border: none;
  border-right: 1px solid #003766;
}

.popaddbtn {
  height: 48px;
  background-color: transparent;
  color: #ff4868;
  width: 100%;
  margin-top: -1px;
  font-size: 17px;
  border: none;
}

.modal-backdrop.show {
  opacity: 0.25;
  background-color: #003766;
}

@media screen and (max-width: 568px) {
  .popovermodal11 {
    position: fixed;
    margin-left: 22%;
    margin-top: 45%;

  }
}

@media screen and (max-width: 450px) {
  .popovermodal11 {
    position: fixed;
    margin-left: 10%;
    margin-top: 45%;

  }
}

@media screen and (max-width: 350px) {
  .popovermodal11 {
    position: fixed;
    margin-left: 0%;
    margin-top: 45%;
    padding: 0px;

  }
}