.addProductFnt {
  font-size: 16px;
  color: #003766;
  font-family: Roboto;
}

.centretext {
  text-align: center;
}

.addProductFnt1 {
  font-size: 16px;
  color: #80cfe6;
  font-family: Roboto;
}

.addProductFnt2 {
  font-size: 14px;
  color: #80cfe6;
  font-family: Roboto;
}

.addProductFnt3 {
  font-size: 14px;
  color: #003766;
  font-family: Roboto;
}

.suppInput1 {
  border: none;

  background-color: #fafafa;
  border-radius: 8px;
  padding: 8px 6px;
  height: 51px;
}

.form-select-lg {
  border: 2px solid #00000029;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  font-size: 16px;
  border-radius: 8px;
  background-color: #fafafa;
}

@media screen and (max-width: 1400px) {
  .addProductFnt {
    font-size: 13px;
    color: #003766;
    font-family: Roboto;
  }
}

@media screen and (max-width: 1400px) {
  .addProductFnt1 {
    font-size: 13px;
    color: #80cfe6;
    font-family: Roboto;
  }
}

/* @media screen and (max-width: 768px) {
  .addProductFnt {
    font-size: 10px;
    color: #003766;
    font-family: Roboto;
  }
} */
.stckFont {
  font-size: 28px;
}

.suppInput2 {
  border: 2px solid #00000029;
  border-radius: 8px;
  padding: 10px;
  /* background-color: #fafafa; */
}

.addProductFnt21 {
  font-size: 16px;
  color: #003766;
  font-family: Roboto;
}

.countBtncanc21 {
  font-size: 15px;
  color: #003766;
  font-weight: 500;
  border: 1px solid #003766;
  background: white !important;

  border-radius: 10px;
}

.custCardCount {
  border-radius: 25px;
}

.sup12 .form-select:focus {
  border-color: #f5e7e7 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem grey !important;
}



.form-select[multiple] option {
  background: none;
  margin: 4px 0;
}

.form-select[multiple] option:checked {
  font-weight: 500;
  color: #003766;
}

.slt-target .custom-select-box {
  height: 51px;
}

.custom-select-box {
  border: 2px solid #00000029;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  font-size: 16px;
  border-radius: 8px;

  background-color: #fafafa;
}

.custom-select-outer {
  /* display: none; */
  overflow: hidden;
  max-height: 120px;
  overflow: auto;
}

.list-select-box {
  color: #003766;
  cursor: pointer;
}

.custom-select-selected {
  cursor: pointer;
  color: #003766 !important;
  font-weight: 400;
  /* font-size: 20px; */
}

.list-select-box.active {
  font-weight: 500;
}

.custom-select-outer.active {
  display: block;
}

/* width */
.custom-select-outer::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.custom-select-outer::-webkit-scrollbar-track {
  background: #fafafa;
}

/* Handle */
.custom-select-outer::-webkit-scrollbar-thumb {
  background: #e3e3e3;
}

/* Handle on hover */
.custom-select-outer::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* for Costum Select start */

.custom-select-box.custom-with-input {
  padding: 0 0;
}

.custom-select-box.custom-with-input .form-control {
  border: none;
}

.custom-select-box.custom-with-input .custom-select-outer .list-select-box {
  padding: 2px 10px 2px 10px;
}

/* for Costum Select end */

.sk-custom-container-prod {
  /* position: relative; */
  /* box-sizing: border-box; */
  border: 2px solid #00000029;
  font-size: 16px;
  border-radius: 8px;
  /* background-color: #fafafa; */
  padding: 0;
}

.sk-custom-container-prod.css-b62m3t-container {
  position: absolute !important;
  box-sizing: border-box;
  width: 91.4%;
}

.field-disable1 {
  pointer-events: none;
}

/* .sk-custom-container.css-b62m3t-container {
  position: absolute !important;
  box-sizing: border-box;
  width: 92.9%;
} */