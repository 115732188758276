.checksection{
    margin-top:15px;
   
   }

  
  .line{
    width: 100%;
text-align: center;
border-bottom: 2px solid #E1E1E1;
border-color:#E1E1E1;
line-height: 0.1em;
margin: 16px 0 20px;
font-family:"Poppins";
font-size:14px;
  }
  .line2{
    width: 100%;
text-align: center;
border-bottom: 2px solid #E1E1E1;
border-color:#E1E1E1;
line-height: 0.1em;
margin: 0px 0 30px;
font-family:"Poppins";
font-size:14px;

  }
  .linetext{
    background:#fff;
 padding: 0 10px;
 color: #003766;
opacity: 1;
  }
  
  .textgreen{
    color:#6EdE64;
  }
  .btnmargin{
     margin-top: 1.5%; 
  }

  .btnmargin1{
    margin-top: 6%; 
 }