.box-contactName {
  background-color: #fafafa;
  padding: 0px 10px;
  border-radius: 5px;
}
.msgoftesxt{
  padding-top: 30px;
}
.box-contactName>input {
  background-color: #fafafa;
  border: none;
  outline: none;
  width: 100%;
}

.fntSupp22 {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
}
.fntSuppclient {
  font-family: Roboto;
  font-size: 12px;
  color: #80cfe6;
}

.fntSupp3 {
  font-family: Roboto;
  /* font-size: 22px; */
  color: #80cfe6;
}

/* @media screen and (max-width: 357px) {
  .fntSupp {
    font-family: Roboto;
    font-size: 20px;
    color: #80cfe6;
  }
} */

.fntSupp1 {
  font-family: Roboto;
  /* font-size: 22px; */
  color: #003766;
}
.fntSupp33 {
  font-family: Roboto;
 font-size: 16px; 
   color: #003766;
   
}

.fntSupp32 {
  font-family: Roboto;
  margin-top: -4px;
   color: #003766;
}
.fntSupp4 {
  font-family: Roboto;
  font-size: Regular;
  color: #003766;
}

.fntSupp2 {
  font-family: Roboto;
  font-size: 28px;
  color: #003766;
}

@media screen and (max-width: 327px) {
  .fntSupp2 {
    font-family: Roboto;
    font-size: 22px;
    color: #003766;
  }
}

.notesForm {
  border: 0.5px solid #80cfe6;
}

.suppInput {
  border: 2px solid #00000029;
  border-radius: 8px;
  padding: 10px;
}

/* .pagination1 {
    float: right;
    margin: 0 0 5px;
}
.pagination1 li a {
    border: none;
    font-size: 13px;
    min-width: 30px;
    min-height: 30px;
    color: #999;
    margin: 0 2px;
    line-height: 30px;
    border-radius: 50% !important;
    text-align: center;
    padding: 0 6px;
}
.pagination1 li a:hover {
    color: #666;
}	
.pagination1 li.active a, .pagination li.active a.page-link {
    background: #80CFE6;
}
.pagination1 li.active a:hover {        
    background: #80CFE6;
}
.pagination1 li.disabled i {
    color: #FAFAFA;
}
.pagination1 li i {
    font-size: 16px;
    padding-top: 6px
} */
.countBtnchange1 {
  border: 1px solid #003766;
  background-color: transparent;
  color: gray;
  font-weight: 650;
  font-size: 15px;
  border-radius: 10px;
}

.fntSupp11 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #003766;
  margin-top: 5px;
}

.stckFont {
  font-size: 28px;
}

.custCardCount{
  filter: drop-shadow(0px 3px  10px #00000029);
}
.str12{
  margin-left: -12px;
  margin-right: -36px;
  
}

.str13{
  margin-right: -12px;
    margin-left: 20px;
}
 .str14{
  margin-left: -12px;
  margin-right: -25px;
 }
 /* .fntSupp31{
  width: 100%;
  font-family: Roboto;
  
  color: #003766;
 } */
 .lev{
  font-size: 12px;
    color: #80cfe6;
 }
