.personalinfrm {
    /* font-family: "Roboto, Medium"; */
    font-size: 20px;
    color: #003766;
    font-weight: 400;
}

.nameHeadings {
    /* font-family: "Poppins"; */
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    /* line-height: 53px; */
    color: #003766;
    cursor: pointer;
    align-self: center;
}

.profileInput {
    border-radius: 10px;
    border: 2px solid #003766;
    padding: 3px;
    font-weight: 400;
    font-size: 20px;
    color: #003766;
}

.profileInput2{
    border-radius: 10px;
    /* border: 2px solid #003766; */
    border: none;
    padding: 3px;
    font-weight: 400;
    font-size: 20px;
    color: #003766;
}

.profileInput3{
    border-radius: 10px;
    /* border: 2px solid #003766; */
    border: none;
    background-color: #fafafa;
    padding: 3px;
    /* font-weight: 400; */
    font-size: 20px;
    color: #003766;
}

.profileSave {
    color: white;
    background-color: #003766;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 650;
    width: 140px;
}

.profileCancel {
    color: #003766;
    background-color: white;
    border: 1px solid #003766;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    width: 140px;
}
.editbtn{
    color: white;
    background-color: #003766;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 650;
    width: 140px;
}
