.PurchasingOrder {
  font-size: 28px;
  font-family: Roboto;
  font-weight: lighter;
  color: #003766;
}

.autosearschitem {
  position: absolute;
  background-color: #fafafa;
  cursor: pointer;
  width: 260px;
  z-index: 1;
  color: #1d1f24;
  font-size: 16px;
  border: none;
  border: 1px solid #707070;
  border-radius: 10px;
}

.signinputSmall:focus-visible {
  outline: none;
}

.signinputSmall {
  width: 100%;
  color: #1d1f24;
  font-size: 20px;
}

.addsuppierbtn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.advisedpart {
  display: flex;
  justify-content: space-between;
}

.suppliertext {
  display: flex;
  /* justify-content: space-between; */
}

.lastpurchasleble {
  font-size: 22px;
  color: #003766;
  font-weight: 400;
}

.suppdivdetails .suppdivdetails-data {
  display: flex;
}

.suppdivdetails-form textarea {
  padding: 10px;
  color: #003766;
}

/* .boxwidth {
  margin-left: -10px;
} */

.suppdivdetails-form textarea::placeholder {
  color: #003766;
  /* height: 120px; */
}

.suppdivdetails::placeholder {
  color: #80cfe6;
  font-size: 14px;
}

#w3review {
  width: calc(100% - 18px);
}

@media (max-width:1199px) {
  #w3review {
    width: calc(100% - 2px);
  }
}


@media (max-width:576px) {
  .supp_padding {
    padding: 0px 10px;
  }

  .Inventory_padding {
    padding-left: 30px;
  }

  .addsuppierbtn {
    padding-right: 20px;
  }

  .showhide2 {
    display: none !important;
  }

}

@media screen and (max-width:991px) {
  .internal_gap {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width:768px) {
  .total_padding {
    padding-left: 10px;
  }
}

@media screen and (max-width:575px) {
  .showhide {
    display: block !important;
  }
}

@media screen and (max-width:459px) {
  .leadTime {
    padding-top: 3rem;
  }
}


.internalHeads {
  font-size: 16px;
  /* color: #003766; */
  font-weight: 500;
}

.suppdivdetails {
  height: 54px;
  /* box-shadow: 0px 3px 12px #00367740; */
  border: 1px solid #daf4fa;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  color: #003766;
  flex: 2;
  font-size: 18px;
}

.partdivdetail {
  height: 46px;
  background: #fafafa 0% 0% no-repeat padding-box;
  /* background-color: #FAFAFA; */
  border-radius: 10px;
  opacity: 1;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  color: #003766;
  /* font-size: 20px; */
  font-size: 18px;
  flex: 2;
}

.addbtnsupper {
  text-decoration: none;
  text-align: left;
  font: normal normal normal 16px/26px Roboto;
  letter-spacing: 0px;
  color: #80cfe6;
}

/* 
.addbtnsupper :hover{
  color: #003766;
} */
.btn-ghost:hover {
  color: #003766 !important;
}

.suppdivdetails h6 {
  color: #80cfe6;
  font-size: 18px;
}

.supppartinpput {
  width: 74%;
  color: #1d1f24;
}

.advispart {
  position: initial !important;
}

.advaiccoard {
  box-shadow: 0px 3px 12px #707070;
}

.addvisepurch {
  color: #003766;
  display: flex;
  justify-content: space-between;
  width: calc(250px - 40px);
}

.adviseRightIcon {
  font-size: 16px !important;
  font-weight: 900 !important;
  background-color: #daf4fa;
  padding: 8px 10px;
  color: black !important;
  position: absolute;
  border-radius: 50%;
  margin-left: 0%;
  top: 10% !important;
  z-index: 100;
}

.adviseRightTrueIcon {
  font-size: 16px !important;
  font-weight: 900 !important;
  background-color: #daf4fa;
  padding: 8px 10px;
  color: black !important;
  position: absolute;
  border-radius: 50%;
  /* margin-left: 84%; */
  top: 10% !important;
  z-index: 100;
}

.advisetext {
  font-size: 18px;
  font-weight: bold;
}

.advisesubtext {
  color: #80cfe6;
  font-size: 14px;
  text-align: end;
}

.subparttext {
  font-size: 16px;
  /* font-weight:; */
  font-weight: 400;
  color: #003766;
  word-break: break-all;
}

.subpartqty {
  font-size: 16px;
  font-weight: bolder;
  color: #003766;
  text-align: end;
}

.customselect .css-qc6sy-singleValue {
  color: #003766;
  font-size: 20px;
  /* color: red; */
}

.kkk .css-qc6sy-singleValue {
  color: #003766;
  font-size: 20px;
  font-weight: 500;
  /* color: red; */
}

/* .ArrowUpDown svg {
  transform: rotate(180deg);
} */

.kkk .css-bxpe4g-control {
  box-shadow: 0 0 0 1px grey;
  border-color: grey;
  /* color: red; */
}

.kkk .css-bxpe4g-control:hover {
  box-shadow: 0 0 0 1px grey;
  border-color: grey;
  /* color: red; */
}

.addvisecol {
  /* box-shadow: 2px 1px 0px 2px #888888; */
  /* box-shadow: 6px 3px 7px -5px #00000029; */
  min-height: 594px;
  border-radius: 0 0 10px 0;
}

.fntparttext {
  font-family: Roboto;
  font-size: 20px;
  color: #003766;
}

.addrowbtn {
  background-color: transparent;
  color: #003766;
  border: none;
}

.signinputpart {
  width: 100%;
  color: #1d1f24;
  font-size: 18px;
}

.countFontclr {
  /* box-shadow: 0px 3px 10px #00000029; */
  border-radius: 24px;
}

.totaletext {
  width: 212px;
  text-align: end;
  height: 56px;
  border-radius: 10px;
  background-color: #fafafa;
  margin-top: -10px;
  /* margin-left: -170px; */
}

.tdinputleble {
  width: 36%;
}

.inppInput {
  border: 2px solid #00000029;
  border-radius: 8px;
  padding: 8px 2px;
  font-size: 20px;
  flex: 1;
  text-align: center;
}

.partdivdetail input[type="number"]::-webkit-inner-spin-button,
.partdivdetail input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

element.style {
  cursor: pointer;
}

.lastaadicon {
  width: 33px;
  font-size: 16px !important;
  font-weight: 900 !important;
  background-color: #e6e6e6;
  padding: 4px;
  color: #fafafa !important;
  position: absolute;
  border-radius: 55%;
  /* margin-left: 0%; */
  /* top: 10% !important; */
  /* z-index: 100; */
  height: 34px;
  text-align: center;
}

.puchasing-order .countFontclr {
  font-size: 28px;
  color: #003766;
}

.puchasing-order .cardHead {
  background-color: #e6e6e6;
  border: none;
  border-radius: 10px 10px 0 0;
}

.puchasing-order .cardHead h5 {
  font-size: 22px;
  color: #003766;
}

.puchasing-order .cardHead img {
  filter: invert(12%) sepia(90%) saturate(2363%) hue-rotate(193deg) brightness(96%) contrast(101%);
}

.puchasing-order .card .addsuppierbtn h6 {
  font-size: 22px;
  color: #003766;
}

.puchasing-order .card .addsuppierbtn a {
  font-size: 16px;
}

.puchasing-order .wrapper .line {
  border-bottom: none;
  width: auto;
}

.puchasing-order .wrapper {
  border-radius: 10px !important;
  border-width: 2px !important;
}

.countBtncanc {
  font-size: 18px;
  color: #003766;
  font-weight: 500;
}

.countBtncancBg {
  background-color: #003766 !important;
  color: #fff !important;
}

.second-division {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: end;
}

.suppliertextTo {
  color: #003766;
  font-size: 20px;
}

.totaletext h6 {
  color: #003766;
  font-size: 20px;
}

/* Model */
.custom-model .modal-content {
  overflow: hidden;
  border-radius: 20px !important;
}

.custom-model .modal-content .modal-body .contents .highlighter {
  font-weight: bold;
}

.custom-model .modal-content .modal-body {
  background-color: #003766;
  color: #fff;
  padding: 30px 40px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.custom-model .modal-footer {
  background-color: #fff;
}

.custom-model .modal-footer {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.custom-model .modal-footer button:first-child {
  border-right: 1px solid #003766;
  font-weight: normal;
  border-radius: 0;
}

.custom-model .modal-footer button {
  width: 50%;
  margin: 0;
  background-color: #fff;
  border: none;
  color: #003766;
  padding-block: 20px;
  font-size: 22px;
  font-weight: bold;
}

.leadtime-top {
  font-size: 22px;
  color: #003766;
}

/* .news {
  box-shadow: 0px -3px 14px -5px #00000029, 0px 5px 7px 0px #00000029;
} */

.puchasing-order .show {
  box-shadow: 0px -3px 7px -5px #00000029, 0px 5px 7px -5px #00000029;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media only screen and (min-device-width: 991px) and (max-device-width: 1440px) {
  .leadtime-top {
    font-size: 20px;
  }
}

/* .customselect .wrapper ul {
  height: 150px;
  overflow-y: scroll;
} */

.puchasing-order .card {
  box-shadow: 0px 3px 10px #00000029;
}

@media screen and (max-width: 1440px) {
  .puchasing-order .card .addsuppierbtn a {
    font-size: 15px;
  }

  .puchasing-order .card .addsuppierbtn h6 {
    font-size: 20px;
  }

  .fntparttext {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .puchasing-order .card .addsuppierbtn a {
    font-size: 15px;
  }

  .puchasing-order .card .addsuppierbtn h6 {
    font-size: 18px;
  }

  .fntparttext {
    font-size: 15px;
  }

  .leadtime-top {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .col-lg-2 {
    flex: 0 0 auto;
    width: 14.666667%;
  }
}

.ordertext {
  font-weight: 700;
}

.countBtnchange12 {
  border: 1px solid #003766;
  background-color: transparent;
  color: #003766;
  font-weight: 650;
  font-size: 15px;
  border-radius: 10px;
  height: 45px;
}

.ArrowUpDownpur svg {
  transform: rotate(180deg);
}


.sk-custom-container {
  /* position: relative; */
  /* position: absolute !important; */
  box-sizing: border-box;
  border: 2px solid #00000029;
  font-size: 16px;
  border-radius: 8px;
  background-color: #fafafa;
  padding: 0;
}

.sk-custom-select__control {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

/* .css-18nt3vc-control {
  border: none !important;
  background: none !important;
  box-shadow: none!important;
} */
.sk-custom-select__menu {
  position: static !important;
  background: none !important;
  margin: 0 !important;
  box-shadow: none !important;
  padding-bottom: 10px;
}

.csk-custom-select__option--is-selected {
  background: none !important;
  color: #003766 !important;
  font-weight: 500 !important;
  padding: 2px 12px !important;
}

/* .css-tr4s17-option {
  background: none;
  color: #003766;
  font-weight: 500;
} */
.sk-custom-select__option--is-focused {
  background: none !important;
  color: #003766 !important;
  font-weight: 400 !important;
  padding: 2px 12px !important;
}

.sk-custom-select__option--is-selected {
  background: none !important;
  color: #003766 !important;
  font-weight: 500 !important;
  padding: 2px 12px !important;
}

.sk-custom-select__single-value {
  background: none !important;
  color: #003766 !important;
  font-weight: 500 !important;
  /* font-size: 18px !important; */
  font-size: 1rem !important;
}

.sk-custom-select__option {
  padding: 2px 12px !important;
}

.sk-custom-select__menu-list {
  max-height: 100px !important;
}

.padding-height .sk-custom-select__value-container {
  padding: 9px 8px;
}


/* width */
.sk-custom-select__menu-list::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.sk-custom-select__menu-list::-webkit-scrollbar-track {
  background: #fafafa;
}

/* Handle */
.sk-custom-select__menu-list::-webkit-scrollbar-thumb {
  background: #e3e3e3;
}

/* Handle on hover */
.sk-custom-select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}


div#sticky-section.sticky-team {
  position: fixed;
  transform: translateX(100%);
  background-color: #fff;
  top: 0;
}

.box {
  width: 120px;
  height: 30px;
  border: 1px solid #999;
  font-size: 18px;
  color: #1c87c9;
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 4px 4px #ccc;
}

.position-reletive {
  position: relative;
}

.po .sk3-custom-container {
  position: absolute !important;
  box-sizing: border-box;
  border: 2px solid #00000029;
  font-size: 16px;
  border-radius: 8px;
  background-color: #fafafa;
  padding: 0;
  width: 98%;
}

.po.composeProduct-drop .sk3-custom-container {
  width: 89%;
}

.po.update-products-drop .sk3-custom-container {
  width: 93%;
}

.dummy-select-container {
  padding: 2px 8px;
  height: 46px;
  display: flex;
  align-items: center;
  color: #003766;
  margin-right: 2px;
  margin-left: 2px;
  font-size: 1rem;
  font-weight: 500;
}