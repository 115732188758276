.rbc .rbc-event {
  padding: 0px !important;
  background-color: transparent;
}

.rbc-event {
  padding-left: 3%;
  border-radius: 8px;
}

.rbc .rbc-calendar {
  height: 650px !important;
  width: 91%;
  background: #ffffff;
}

.rbc .rbc-timeslot-group {
  min-height: 70px;
}

.rbc .rbc-event-label {
  display: none !important;
}

.rbc .rbc-date-cell {
  text-align: center !important;
}

.normal-card,
.concret-block-card {
  background-color: #daf4fa !important;
  color: #003766 !important;
  border-radius: 8px;
  padding: 0px;
  font-size: 14px;
  height: 100%;
  border: 0.1px solid #00376638;
  position: relative;
  overflow: hidden;
}

.concret-block-card {
  background-color: #fded91 !important;
}

.timelinecard2 {
  /* background-image: url("../../assets/icons/Path554.svg"); */
  /* background-repeat: no-repeat;
  background-size: 15% 100%;
  background-position: absolute;
  overflow: hidden;
  background-position: -5px 0; */
  padding-left: 8px;

}

.timelinecard3 {
  /* background-image: url("../../assets/icons/Path554.svg");
  background-repeat: no-repeat;
  background-size: 2% 100%;
  background-position: absolute;
  overflow: hidden;
  background-position: -5px 0; */
  padding-left: 8px;
}

.homeBox1 {
  font-size: 12px;
}

.homeBox1view {
  width: 15px;
  height: 15px;
  font-size: 12px;
  margin-top: -4px;
}

.timelinetxt {
  font-size: 10px;
  font-weight: 500;
}

.calender-headerbtn {
  background-color: transparent !important;
  color: #003766 !important;
  font-weight: bold;
  border: none !important;
}

.calender-headerbtn1 {
  background-color: #daf4fa !important;
  color: #003766 !important;
  font-weight: bold;
  border-radius: 7px !important;
  border: 1px solid #003766 !important;
  margin: 5px !important;
}

.calender-headerbtn2 {
  background-color: transparent !important;
  color: #80cfe6 !important;
  font-weight: 700;
  border-radius: 4px !important;
  padding: 8px 18px !important;
  cursor: pointer !important;
}

.calender-headerbtn3 {
  background-color: transparent !important;
  color: #003766 !important;
  font-weight: bold;
  border: none !important;
  padding-top: 0px !important;
  cursor: pointer !important;
}

.rbcheader .dropdown-toggle::after {
  margin-left: 1.255em !important;
}

.selected-headerbtn1 {
  background-color: #75d1ea !important;
  color: #003766 !important;
  font-weight: bold;
  border-radius: 7px !important;
  border: 1px solid #003766 !important;
}

.view-btn {
  min-width: 120px !important;
  margin-top: 10px !important;
}

.rbcheader .dropdown-arrow {
  font-size: 15px !important;
  font-weight: 900 !important;
  background-color: #80cfe6;
  padding: 4px;
  color: black !important;
  position: absolute;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  z-index: 100;
}

.rbc-day-slot .rbc-event-content {}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.rbc-toolbar button {
  border: none;
}

.rbc-toolbar button:active {
  box-shadow: none;
}

.rbc-header {
  border-bottom: none;
  color: #003766;
  text-rendering: auto;
}

.rbc-button-link {
  /* text-align: center; */
  padding-top: 20px;
}

.rbc-time-view .rbc-allday-cell {
  height: 5px;
}

.rbc-time-view .rbc-row {
  /* min-height: 0px; */
}

.rbc-time-header.rbc-overflowing {
  border: none;
}

.rbc-day-bg+.rbc-day-bg {
  border: none;
}

.rbc-time-slot {
  padding-top: 20px;
  text-align: center;
}

.rbc-day-slot .rbc-event {
  border: none;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none;
}

.dropdown-item:focus,
.dropdown-item {
  font-weight: 500;
}

.dropdown-item.active-menu {
  font-weight: 700;
  color: #80CFE6 !important;
}

.rbc-time-header-content {
  border: none;
}

.rbc-time-view {
  border: none;
}

.rbc-header+.rbc-header {
  border: none;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background- {
  outline: none;
}

/* .dropdown-item:focus, .dropdown-item::target-text {
font-weight: 700;
} */

/* .rbc-day-slot .rbc-events-container {
  margin-bottom: 29px;
} */

.timelinecard2:after,
.timelinecard3:after {
  content: "";
  background-image: url("../../assets/icons/Path554.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
  background-position: 0 0;
  position: absolute;
  height: 28px;
  width: 10px;
}


.rbc-time-view .rbc-allday-cell {
  display: none;
}

.timelinecard2:after,
.timelinecard3:after {
  height: 38px;
  width: 13px;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  min-height: 36px !important;
}

.ticket-text {
  font-size: 11px;
}

.ticket-text.productname_text {
  font-size: 13px;
  text-overflow: ellipsis;
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
}

.ticket-text.ordercode_text {
  font-weight: bold;
}

.ticket-text.ticket-date {
  font-weight: bold;
}

.ticket-text.clientname_text {
  text-overflow: ellipsis;
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
}

.ticket-text.ordercode_text .Quote-text {
  font-weight: 500;
  color: #a7a7a7;
}

.text-order-product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 13px;
  color: #003766;
  /* line-height: 21px; */
}

.text-order-client-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  color: #003766;
  /* line-height: 19px; */
}

.text-order-quote {
  text-align: end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 11px;
  color: #003766;
  /* line-height: 19px; */
}

.text-order-quote span {
  font-size: 9px;
  color: #7E7E7E;
  /* line-height: 16px; */
}

.text-order-due-date {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: end;
  font-weight: bold;
  font-size: 11px;
  color: #003766;
  /* line-height: 19px; */
}

.text-order-due-date img {
  width: 13px;
  height: 13px;
  margin-top: -2px;
}

.special-part-icon {
  position: absolute;
  height: 100%;
  left: -3px;
}

.special-concret-part-icon {
  position: absolute;
  height: 96%;
  left: -2px;
}

.special-part-padding {
  padding-left: 14px;
  padding-right: 6px;
}

.normal-padding {
  padding-left: 6px;
  padding-right: 6px;
}

.order-error-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  background-color: #fc255f;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 1;
}

.stock-assignee-missing-tooltip .tooltip-arrow::before {
  border-top-color: #fc255f;
}

.stock-assignee-missing-tooltip .tooltip-inner {
  font-size: 11px;
  background-color: #fc255f;
}